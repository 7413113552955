import * as React from "react";

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl
} from '@mui/material';


class DestinationBannerScreenCard extends React.Component {
  state = {
    shopValue: this.props.shopValue,
    selected: this.getInitialSelected,
  };

  //
  getInitialSelected = () => {
    const shopValue = this.props.shopValue;
    const bannerScreens = shopValue.bannerScreens;
    var options = [];
    for (const bs of bannerScreens) {
      var bannerScreenTuple = {
        label: bs.title,
        value: "" + bs.bannerScreenId,
      };
      options.push(bannerScreenTuple);
    }

    // default selection
    var selected = this.state.selected;
    if (null == selected) {
      if (options.length > 0) {
        selected = options[0].value;
      }
    }

    return selected;
  }


  //
  handleSelectChange = (e) => {
    // remember the selectuon
    this.setState({ selected: e.target.value });

    // tell the parent
    this.props.bannerScreenSelected(e.target.value);
  };


  //
  render() {
    // default selection, then tell parent to enable the Save button
    var selected = this.state.selected;
    if (selected === undefined) {
      selected = this.getInitialSelected();
    }

    const divStyle = {
      width: '400px',
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: '0px',
      marginTop: '5px',
      padding: '5px',
      border: '1px solid #000', // Adjust the color and width as needed
      padding: '10px', // Add padding for better visual appearance
    }



    return (
      <div style={divStyle}>

        <FormControl component="fieldset">
          <RadioGroup
            value={selected}
            onChange={this.handleSelectChange}
          >
            {this.state.shopValue.bannerScreens.map((screen) => (
              <FormControlLabel
                key={screen.bannerScreenId}
                value={screen.bannerScreenId}
                control={<Radio size="small" />}
                label={screen.title}
              />
            ))}
          </RadioGroup>
        </FormControl>

      </div>
    );

  }
}

export default DestinationBannerScreenCard;
