import * as React from "react";

import {
  Card,
  Checkbox,
  FormControlLabel
} from '@mui/material';



class CategoriesCard extends React.Component {
  state = {
    shopValue: this.props.shopValue,
    categories: this.props.categories,
  };


  //
  handleSelectChange = (checkboxName, checked) => {
    var label = checkboxName.target.id;
    var shopValue = this.state.shopValue;
    if (checked === true) {
      shopValue.categories.push(label);
    } else {
      var index = shopValue.categories.indexOf(label);
      if (index !== -1) {
        shopValue.categories.splice(index, 1);
      }
    }

    this.setState({ shopValue });
    this.props.shopCategoriesSelected(shopValue.categories);
  };


  //
  render() {
    const categoriesDict = this.state.categories;
    const catValues = Object.values(categoriesDict);
    catValues.sort();

    const shopCategories = this.state.shopValue.categories;

    return (
      <Card>
        <label
          style={{
            paddingLeft: '20px',
            paddingTop: '10px',
            display: 'flex',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}>Categories:</label>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '15px'
          }}>
          {catValues.map((item, index) => (
            <div key={item} className="checkbox-container">
              <FormControlLabel
                control={
                  <Checkbox
                    id={item}
                    checked={shopCategories.includes(item)}
                    onChange={this.handleSelectChange}
                    style={{ margin: '-5px 0' }}
                  />
                }
                label={item}
              />
            </div>
          ))}
        </div>
      </Card>
    );
  }
}

export default CategoriesCard;
