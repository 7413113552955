import React from 'react';
import axios from 'axios';

import { Box, Button, Card } from '@mui/material';

import { INDICATOR_COLOR } from './components/constants/bb-constants';

import ShopAppearance from './components/ShopAppearance';
import Notifications from './components/Notifications';
import ShopAdmin from './components/ShopAdmin';
import NavigationParentCard from './components/NavigationParentCard'
import CarouselBuilder from './components/CarouselBuilder'
import BannerScreenBuilder from './components/BannerScreenBuilder'

export const display_mode_admin = "display_mode_admin";
export const display_mode_edit_shop = "display_mode-edit_shop";
export const display_mode_navigation = "display_mode_navigation";
export const display_mode_carousel_builder = "display_mode-carousel_builder";
export const display_mode_bannerScreen_builder =
  "display_mode_bannerScreen_builder";
export const display_mode_notifications = "display_mode_notifications";



// function BBTabPanel(props) {
class BBTabPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shopValue: props.shopValue,
      categories: props.categories,
      availableCarousels: props.availableCarousels,
      bannerScreens: props.bannerScreens,
      shopID: props.shopID,
      display_mode: display_mode_admin
    };
  }


  // Functions from Buttons to show "Tabs"
  showAdmin = () => {
    var display_mode = display_mode_admin
    this.setState({ display_mode })
  }
  showShop = () => {
    var display_mode = display_mode_edit_shop
    this.setState({ display_mode })
  }
  showNavigation = () => {
    var display_mode = display_mode_navigation
    this.setState({ display_mode })
  }
  showCarouselBuilder = () => {
    var display_mode = display_mode_carousel_builder
    this.setState({ display_mode })
  }
  showScreenBuilder = () => {
    var display_mode = display_mode_bannerScreen_builder
    this.setState({ display_mode })
  }
  showNotifications = () => {
    var display_mode = display_mode_notifications
    this.setState({ display_mode })
  }

  setShop = (e) => {
    var shopValue = e
    this.setState({ shopValue })
  }

  //
  updateNavigation = (navigation) => {
    var shopValue = this.state.shopValue;
    shopValue.navigation = navigation;

    this.setState({ shopValue });
  };


  // Carousel Builder
  //
  isCarouselInUse(carousel) {
    var bannerScreens = this.state.bannerScreens;
    var thisCarouselId = carousel.carouselId;

    for (const bs of bannerScreens) {
      var carousels = bs.carousels;
      for (const carousel of carousels) {
        var id = carousel.carouselId;
        if (thisCarouselId == id) {
          return true;
        }
      }
    }

    return false;
  }

  // Carousel Builder
  //
  updateAvailableCarousels = (availableCarousels) => {
    // TODO - this appears to be obsolete
  };


  //
  fetchShopForShopID = () => {
    var shopID = this.state.shopID

    var payload = {
      shopID: shopID
    };

    var scope = this;
    let xhr = new XMLHttpRequest();
    const BASE_URL = "https://qob7bezeh8.execute-api.us-east-1.amazonaws.com/dev";
    let url = BASE_URL + "/get-shopData";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var data = JSON.parse(this.responseText);
        scope.handleShopDataDownloaded(data);
      } else {
        // TODO - Error message to User
        console.log("fetchShopForShopID not 200  status: ", xhr.status);
      }
    };

    var data = JSON.stringify(payload);
    xhr.send(data);
  };


  // called when the Shop Data has been downloaded from the API
  handleShopDataDownloaded = (e) => {
    if (null == e)
      return;

    // Store the Shop
    const shop = e["shop"];
    const availableCarousels = e["availableCarousels"];
    const bannerScreens = e["bannerScreens"];
    const categories = e["categories"];

    const shopID = shop.shopId;

    this.setState({
      shopValue: shop,
      shopID: shopID,
      availableCarousels: availableCarousels,
      bannerScreens: bannerScreens,
      categories: categories,
      isShopLoaded: true,
    });

    // ??? props?
    // Do we need the above logic if this.props call works?  will it propogate?
    this.props.setShopData(shop, availableCarousels, bannerScreens, categories)
  }



  // Render the page
  render() {

    const cardStyle = {
      padding: '20px', // Add padding to the Card
    };

    const shopValue = this.state.shopValue
    const shopID = shopValue.shopId
    const apiKey = shopValue.api.apiKey
    const shopDomain = shopValue.api.shopDomain

    // Carousel Builder 
    // Mark any Carousels as 'in-use' to prevent Delete breaking the BannerScreen
    var markedCarousels = this.state.availableCarousels;
    for (const carousel of markedCarousels) {
      var carouselInUse = this.isCarouselInUse(carousel);
      carousel["carouselInUse"] = carouselInUse;
    }


    // Which Card to show based on "selected Tab"
    var selectedTab = <div>Selected Tab</div>
    const displayMode = this.state.display_mode

    if (displayMode === display_mode_admin) {
      selectedTab =
        <ShopAdmin
          shopValue={this.state.shopValue}
          setShop={this.setShop}
          categories={this.state.categories}
          updateShopValue={this.setShop}
        />
    } else if (displayMode === display_mode_edit_shop) {
      selectedTab =
        <ShopAppearance
          shopValue={this.state.shopValue}
          setShop={this.setShop}
          categories={this.state.categories}
          updateShopValue={this.setShop}
        />
    } else if (displayMode === display_mode_navigation) {
      selectedTab =
        <NavigationParentCard
          shopValue={this.state.shopValue}
          setShop={this.setShop}
          navigation={this.state.shopValue.navigation}
          updateNavigation={this.updateNavigation}
        />
    } else if (displayMode === display_mode_carousel_builder) {
      selectedTab =
        <CarouselBuilder
          id="carouselBuilder-id"
          shopValue={this.state.shopValue}
          availableCarousels={markedCarousels}
          updateAvailableCarousels={this.updateAvailableCarousels}
          shopID={shopID}
        />

      // selectedTab = <div>Carousel Builder</div>
    } else if (displayMode === display_mode_bannerScreen_builder) {
      // selectedTab = <div>Banner Screen Builder</div>
      selectedTab =
        <BannerScreenBuilder
          // dirty={this.state.dirty}
          shopValue={this.state.shopValue}
          shopID={shopID}
          availableCarousels={this.state.availableCarousels}
          bannerScreens={this.state.bannerScreens}
          bannerScreenId={0}
          reloadParent={this.fetchShopForShopID}
        // reloadParent={this.fetchShopForHostDomain}
        />

    } else if (displayMode === display_mode_notifications) {
      selectedTab =
        <Notifications
          shopID={shopID}
          apiKey={apiKey}
          shopDomain={shopDomain}
        />
    }

   
    return (
      <Box key={this.state.shopValue}>
        <Card variant="outlined" style={cardStyle} >
          <Button
            style={{
              backgroundColor: this.state.display_mode === display_mode_admin ? INDICATOR_COLOR : 'transparent',
            }}
            onClick={this.showAdmin}>
            Admin
          </Button>
          <Button
            style={{
              backgroundColor: this.state.display_mode === display_mode_edit_shop ? INDICATOR_COLOR : 'transparent',
            }}
            onClick={this.showShop}>
            Appearance
          </Button>
          <Button
            style={{
              backgroundColor: this.state.display_mode === display_mode_navigation ? INDICATOR_COLOR : 'transparent',
            }}
            onClick={this.showNavigation}>
            Navigation
          </Button>
          <Button
            style={{
              backgroundColor: this.state.display_mode === display_mode_carousel_builder ? INDICATOR_COLOR : 'transparent',
            }}
            onClick={this.showCarouselBuilder}>
            Carousel Builder
          </Button>
          <Button
            style={{
              backgroundColor: this.state.display_mode === display_mode_bannerScreen_builder ? INDICATOR_COLOR : 'transparent',
            }}
            onClick={this.showScreenBuilder}>
            Screen Builder
          </Button>
          <Button
            style={{
              backgroundColor: this.state.display_mode === display_mode_notifications ? INDICATOR_COLOR : 'transparent',
            }}
            onClick={this.showNotifications}>
            Notifications
          </Button>
        </Card>
        <Card>
          {selectedTab}
        </Card>
      </Box>
    );
  }
}

export default BBTabPanel
