import * as React from "react";

import { Card, TextField } from '@mui/material';

class DestinationSearchResultsCard extends React.Component {
  state = {
    searchTerm: this.props.searchTerm,
  };

  //
  render() {
    return (
      <div>
        <label>Search Term:</label>
        <input
          value={this.state.searchTerm}
          onChange={this.handleKeyentrySearchTermStateChange}
          onKeyPress={this.handleKeypress}
        />
      </div>
    );
  }

  // Save Collection
  handleKeyentrySearchTermStateChange = (e) => {
    var searchTerm = e.target.value;
    this.setState({ searchTerm: searchTerm });
    this.props.handleSearchTermSelection(searchTerm);
  };
}

export default DestinationSearchResultsCard;
