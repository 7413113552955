import React from 'react';
import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';

import BBTabPanel from './BBTabPanel'

import {
  Card,
} from '@mui/material';


// constants
export const display_mode_login = "display_mode_login";
export const display_mode_reset_password = "display_mode_reset_password";
export const display_mode_show_shop = "display_mode_show_shop";



class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMode: display_mode_login,
      value: ''
    };
  }

  //
  setShopData = (shop, availableCarousels, bannerScreens, categories) => {
    var shopID = shop.shopId;
    this.setState({
      displayMode: display_mode_show_shop,
      shopValue: shop,
      shopID: shopID,
      availableCarousels: availableCarousels,
      bannerScreens: bannerScreens,
      categories: categories,
      isShopLoaded: true,
    });
  };


  //
  gotoLoginScreen = () => {
    this.setState({
      displayMode: display_mode_login
    })
  };


  //
  requirePasswordReset = () => {
    this.setState({
      displayMode: display_mode_reset_password
    })
  };


  //
  render() {
    var loginFormDiv =
      <div>        
        <Card
          style={{
            width: '450px',
            marginLeft: '50px',
            marginTop: '50px',
            border: '1px solid #000',
            padding: '10px',
          }}>
          
          <br /><br />
          
          <label style={{
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}>
            Login
          </label>
          
          <br /><br />

          <LoginForm
            setShopData={this.setShopData}
            requirePasswordReset={this.requirePasswordReset}
          />

        </Card>
      </div>;

    var resetPassword =
      <div>        
        <Card
          style={{
            width: '500px',
            marginLeft: '50px',
            marginTop: '50px',
            border: '1px solid #000',
            padding: '10px',
          }}>
          
          <br /><br />
          
          <label style={{
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}>
            Reset Password
          </label>
          
          <br /><br />

          <ResetPassword
            gotoLoginScreen={this.gotoLoginScreen}
          />

        </Card>
      </div>;



    const shopID = this.state.shopID
    const categories = this.state.categories

    var showShop =
      <div key={this.state.shopValue}>
        <BBTabPanel
          shopValue={this.state.shopValue}
          categories={categories}
          shopID={shopID}
          availableCarousels={this.state.availableCarousels}
          bannerScreens={this.state.bannerScreens}
          setShopData={this.setShopData}
        />
      </div>;


    var divToDisplay;
    var displayMode = this.state.displayMode
    if (displayMode === display_mode_login) {
      divToDisplay = loginFormDiv
    } else if (displayMode === display_mode_reset_password) {
      divToDisplay = resetPassword
    } else if (displayMode === display_mode_show_shop) {
      divToDisplay = showShop
    }


    return (
      divToDisplay
    );
  }
}

export default LoginPage
