import * as React from "react";
import axios from 'axios';

import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';


class DestinationCollectionCard extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      collectionIdReadable: this.props.collectionIdReadable,
      collectionId: this.props.collectionId,
      availableCollections: [],
      apiKey: this.props.apiKey,
      shopDomain: this.props.shopDomain,
      showFormControl: false,
    };

    this.fetchCollections();
  }

  //
  toggleFormControlVisibility = () => {
    this.setState((prevState) => ({
      showFormControl: !prevState.showFormControl,
    }));
  };


  //
  render() {

    const divStyle = {
      width: '400px',
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: '0px',
      marginTop: '5px',
      padding: '5px',
    }

    const formControlStyle = {
      transition: `opacity 1s ease ${this.state.showFormControl ? '1.0s' : '1.0s'}`, // Adjust durations accordingly
      opacity: this.state.showFormControl ? 1 : 0, // Set initial opacity based on showFormControl state
    };

    return (
      <div
        style={{
          border: '1px solid #000',
          padding: '10px',
        }}>

        <div style={divStyle}>
          <label style={{ margin: '7px' }}>Selected: </label>
          <label style={{ margin: '7px', marginLeft: '15px' }}>
            {this.state.collectionIdReadable}
          </label>

          <Button 
            onClick={this.toggleFormControlVisibility}
            style={{ marginBottom: '-15px', marginLeft: '40px', paddingBottom: '10px' }}
          > 
            {this.state.showFormControl ? 'Hide Collections' : 'Show Collections'}
          </Button>

        </div>

        <div style={formControlStyle}>
          {this.state.showFormControl && (
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.collectionId}
                onChange={this.handleCollectionSelection}
              >
                {this.state.availableCollections.map((Collection) => (
                  <FormControlLabel
                    key={Collection.node.id}
                    value={Collection.node.id}
                    control={<Radio size="small" />}
                    label={Collection.node.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </div>
      </div>
    );
  }


  // Save Collection
  handleCollectionSelection = (e, newCollectionId) => {
    const collections = this.state.availableCollections
    var collectionIdReadable, collectionId;

    // get relevant data from the Collection
    if (collections) {
      for (const collection of collections) {
        if (newCollectionId == collection.node.id) {
          collectionIdReadable = collection.node.title;
          collectionId = newCollectionId;
          break;
        }
      }
    }

    // tell parent a selection was made to enable the Save button as needed
    this.props.handleValueEntered();

    // persist to parent/caller
    this.props.onSelection(collectionIdReadable, collectionId);

    //
    this.setState({ collectionId, collectionIdReadable });

  };


  //
  fetchCollections = async () => {
    // this.setState({ loadingCollections: true })

    // see if we already have the Collections
    var availableCollections = this.state.availableCollections
    if (availableCollections === null && availableCollections.length > 0) {
      return availableCollections;
    }

    try {
      const storefrontAccessToken = this.state.apiKey;
      const shopifyStoreUrl = 'https://' + this.state.shopDomain;

      const response = await axios.post(
        `${shopifyStoreUrl}/api/2021-10/graphql.json`,
        {
          query: `
            {
              collections(first: 100) {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
            }
          `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
          },
        }
      );

      availableCollections = response.data.data.collections.edges
      this.setState({
        availableCollections,
        loadingCollections: false
      });
      return availableCollections;

    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

}

export default DestinationCollectionCard;
