import * as React from "react";

import { Button, Card, FormGroup } from '@mui/material';

import * as bbConstants from "./constants/bb-constants";

import DestinationProductCard from "./DestinationProductCard";
import DestinationCollectionCard from "./DestinationCollectionCard";
import DestinationBannerScreenCard from "./DestinationBannerScreenCard";
import DestinationSearchResultsCard from "./DestinationSearchResultsCard";
import DestinationURLCard from "./DestinationURLCard";
import IconPickerCard from "./IconPickerCard";

class NavigationTab extends React.Component {
  state = {
    saveButtonDisabled: false, // always enabled?
    originalNavigationTab: this.props.navigationTab,
    shopValue: this.props.shopValue,
  };

  moveTabLeftPressed = () => {
    this.handleSaveTab();
    var navigation = this.getNavigation();
    this.props.moveTabLeft(navigation);
  };

  moveTabRightPressed = () => {
    this.handleSaveTab();
    var navigation = this.getNavigation();
    this.props.moveTabRight(navigation);
  };

  // Propagates the Navigation/Tab values to Parent
  handleSaveTab = () => {
    this.setState({ saveButtonDisabled: true });

    var navigation = this.getNavigation();

    var featureType = navigation.featureType;
    switch (featureType) {
      case bbConstants.DESTINATION_PLP:
      case bbConstants.DESTINATION_PRODUCT:
        navigation.extraData = navigation.product_extraData;
        navigation.extraDataReadable = navigation.product_extraDataReadable;
        break;

      case bbConstants.DESTINATION_COLLECTION:
        navigation.extraData = navigation.collection_extraData;
        navigation.extraDataReadable = navigation.collection_extraDataReadable;
        break;

      case bbConstants.DESTINATION_URL:
        navigation.extraData = navigation.url_extraData;
        navigation.extraDataReadable = null;
        break;

      case bbConstants.DESTINATION_BANNERS:
        break;

      case bbConstants.DESTINATION_SEARCH:
        navigation.extraData = navigation.search_extraData;
        navigation.extraDataReadable = null;
        break;

      case bbConstants.DESTINATION_ALL_COLLECTION_BANNERS_SCREEN:
        navigation.extraData = "";
        navigation.extraDataReadable = "";
        break;
    }

    this.setState({
      originalNavigationTab: navigation,
      modifiedlNavigationTab: null,
    });

    this.props.updateNavigation(navigation);
  };

  //  Sets 'dirtiness' of the state
  handleValueWasEntered = () => {
    this.setState({ saveButtonDisabled: false });
  };

  //
  getNavigation() {
    var navigation = this.state.modifiedlNavigationTab;
    if (null == navigation) {
      navigation = this.state.originalNavigationTab;
    }

    if (null == navigation) {
      navigation = {
        extraData: "",
        extraDataReadable: "",
        featureType: bbConstants.DESTINATION_ALL_COLLECTION_BANNERS_SCREEN,
        image: "nav_home",
        title: "",
      };
    }
    return navigation;
  }

  //
  handleChangeTitle = (e) => {
    this.handleValueWasEntered();
    var title = e.target.value;
    var navigation = this.getNavigation();
    navigation.title = title;
    this.setState({ modifiedlNavigationTab: navigation });
    this.props.updateNavigation(navigation);
  };

  //
  handleChangeImage = (iconId) => {
    this.handleValueWasEntered();
    var image = iconId;
    var navigation = this.getNavigation();
    navigation.image = image;
    this.setState({ modifiedlNavigationTab: navigation });
    this.props.updateNavigation(navigation);
  };

  //  Destination Type Radio Buttons
  handleDestinationTypeStateChange = (e) => {
    // User needs to set a value for most destinations
    // Disable the Button when changing Cards - reEnable with a callback when the user sets the vlaue
    var saveButtonDisabled = true;

    var featureType = e.target.value;
    switch (featureType) {
      case bbConstants.DESTINATION_PLP:
      case bbConstants.DESTINATION_PRODUCT:
      case bbConstants.DESTINATION_COLLECTION:
      case bbConstants.DESTINATION_URL:
      case bbConstants.DESTINATION_BANNERS:
      case bbConstants.DESTINATION_SEARCH:
        saveButtonDisabled = true;
        break;

      case bbConstants.DESTINATION_ALL_COLLECTION_BANNERS_SCREEN:
        saveButtonDisabled = false;
        break;

      default:
        saveButtonDisabled = true;
    }

    var navigation = this.getNavigation();
    navigation.featureType = featureType;
    this.setState({
      featureType,
      saveButtonDisabled,
      modifiedlNavigationTab: navigation,
    });

    this.props.updateNavigation(navigation);
  };

  //
  handleSearchTermSelection = (e) => {
    this.handleValueWasEntered();
    var searchTerm = e;
    var navigation = this.getNavigation();
    navigation.search_extraData = searchTerm;
    navigation.extraData = searchTerm;
    this.setState({ modifiedlNavigationTab: navigation });
    this.props.updateNavigation(navigation);
  };

  //
  handleURLSelection = (e) => {
    this.handleValueWasEntered();
    var url = e;
    var navigation = this.getNavigation();
    navigation.url_extraData = url;
    navigation.extraData = url;
    this.setState({ modifiedlNavigationTab: navigation });
    this.props.updateNavigation(navigation);
  };

  // BannerScreen
  bannerScreenSelected = (bannerScreenId) => {
    this.handleValueWasEntered();
    var navigation = this.getNavigation();
    navigation.extraData = bannerScreenId;
    this.setState({ modifiedlNavigationTab: navigation });
    this.props.updateNavigation(navigation);
  };

  //  Product Selected
  handleProductSelection = (title, id) => {
    this.handleValueWasEntered();
    var navigation = this.getNavigation();
    navigation.extraData = id;
    navigation.extraDataReadable = title;
    navigation.product_extraData = id;
    navigation.product_extraDataReadable = title;
    this.setState({ navigation });
    this.props.updateNavigation(navigation);
  };

  //  Collection Selected
  handleCollectionSelection = (title, id) => {
    this.handleValueWasEntered();
    var navigation = this.getNavigation();
    navigation.extraData = id;
    navigation.extraDataReadable = title;
    navigation.collection_extraData = id;
    navigation.collection_extraDataReadable = title;
    this.setState({ navigation });
    this.props.updateNavigation(navigation);
  };

  // ----- ----- ----- -----
  //          Render
  // ----- ----- ----- -----
  render() {
    // -----
    // Logic for setting the Product/Collection display String
    // -----
    var productId = "";
    var productIdReadable = "";
    var collectionId = "";
    var collectionIdReadable = "";
    var destinationURL = "";

    // check for the modifiedNavigationTab
    var navigation = this.state.modifiedlNavigationTab;

    // default to the original if the modifiedNavigationTab hasn't been made
    if (navigation == null) {
      navigation = this.state.originalNavigationTab;

      // no tab data - new tab?
      if (navigation == null) {
        navigation = {
          extraData: "",
          extraDataReadable: "",
          featureType: "",
          image: "",
          title: "",
        };
      }

      productId = navigation.extraData;
      productIdReadable = navigation.extraDataReadable;
      collectionId = navigation.extraData;
      collectionIdReadable = navigation.extraDataReadable;
      destinationURL = navigation.extraData;
    } else {
      // modified values
      productId = navigation.product_extraData;
      productIdReadable = navigation.product_extraDataReadable;
      collectionId = navigation.extraData;
      collectionIdReadable = navigation.extraDataReadable;
      destinationURL = navigation.url_extraData;
    }

    // -----
    // End Logic for setting the Product/Collection display String
    // -----

    //
    const destinationCards = [

      // Products
      <DestinationProductCard
        onSelection={this.handleProductSelection}
        handleValueEntered={this.handleValueWasEntered}
        productId={productId}
        productIdReadable={productIdReadable}
        apiKey={this.state.shopValue.api.apiKey}
        shopDomain={this.state.shopValue.api.shopDomain}
      />,

      // Collections
      <DestinationCollectionCard
        onSelection={this.handleCollectionSelection}
        handleValueEntered={this.handleValueWasEntered}
        collectionId={collectionId}
        collectionIdReadable={collectionIdReadable}
        apiKey={this.state.shopValue.api.apiKey}
        shopDomain={this.state.shopValue.api.shopDomain}
      />,

      // All Collections
      <Card>{/* place holder? */}</Card>,

      // URL
      <DestinationURLCard
        title={this.props.featureString}
        handleURLSelection={this.handleURLSelection}
        onSelection={this.props.handleSearchSelection}
        destinationURL={destinationURL}
      />,

      // Banner Screen
      <DestinationBannerScreenCard
        title={this.props.featureString}
        shopValue={this.props.shopValue}
        bannerScreenSelected={this.bannerScreenSelected}
        handleValueEntered={this.handleValueWasEntered}
      />,

      // Search Results
      <DestinationSearchResultsCard
        title={this.props.featureString}
        handleSearchTermSelection={this.handleSearchTermSelection}
        onSelection={this.props.handleSearchSelection}
        searchTerm={navigation.extraData}
      />,
    ];

    // var navigation = this.props.navigation
    var selectedDestinationCard = destinationCards[0];

    if (
      navigation.featureType === bbConstants.DESTINATION_PLP ||
      navigation.featureType === bbConstants.DESTINATION_PRODUCT
    ) {
      selectedDestinationCard = destinationCards[0];
    } else if (navigation.featureType === bbConstants.DESTINATION_COLLECTION) {
      selectedDestinationCard = destinationCards[1];
    } else if (
      navigation.featureType ===
      bbConstants.DESTINATION_ALL_COLLECTION_BANNERS_SCREEN
    ) {
      selectedDestinationCard = destinationCards[2];
    } else if (navigation.featureType === bbConstants.DESTINATION_URL) {
      selectedDestinationCard = destinationCards[3];
    } else if (navigation.featureType === bbConstants.DESTINATION_BANNERS) {
      selectedDestinationCard = destinationCards[4];
    } else if (navigation.featureType === bbConstants.DESTINATION_SEARCH) {
      selectedDestinationCard = destinationCards[5];
    }

    var disabledDeleteButton = this.props.disabledDeleteButton;

    const divStyle = {
      width: '450px',
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: '15px',
      marginTop: '5px',
      padding: '5px',
      border: '1px solid #000',
      padding: '10px',
    }
    const labelStyle = {
      marginTop: '5px',
    }

    const space_10 = <div style={{ height: '10px' }}></div>

    return (
      <Card variant="outlined"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: '15px',
          padding: '5px'
        }}
      >

        <div style={divStyle}>
          <label style={labelStyle}>Tab Order:</label>

          <Button
            size="small"
            variant="contained"
            style={{ marginLeft: '15px' }}
            onClick={this.moveTabLeftPressed}
          >
            Move Left
          </Button>

          <Button
            size="small"
            variant="contained"
            style={{ marginLeft: '15px' }}
            onClick={this.moveTabRightPressed}
          >
            Move Right
          </Button>

          <Button
            onClick={this.props.onDeleteButtonPressed}
            size="small"
            variant="contained"
            style={{ marginLeft: '35px', backgroundColor: 'red', color: 'white' }}
          >
            Delete Tab
          </Button>


        </div>

        <div style={{ height: '10px' }}></div>

        <div style={divStyle}>
          <label style={labelStyle}>Tab Name:</label>
          <input
            size="20"
            defaultValue={navigation.title}
            onChange={this.handleChangeTitle}
            style={{
              marginLeft: '15px',
              marginTop: '5px'
            }}
          />
        </div>


        <div style={{
          width: '450px',
          border: '1px solid #000',
          padding: '10px',
          textAlign: 'left',
          marginLeft: '15px',
          marginTop: '15px',
        }}>
          <label style={{
            marginLeft: '0px',
          }}>Tab Icon:</label>
          <div style={{
            height: '10px',
          }}></div>
          <IconPickerCard
            buttonText="Icon"
            iconId={navigation.image}
            handleChangeImage={this.handleChangeImage}
            defaultValue="nav_default_value"
          />
        </div>


        <div style={{
          textAlign: 'left',
          marginLeft: '15px',
          marginTop: '15px',
          width: '440px',
          border: '1px solid #000',
          padding: '15px',
        }}>
          <label style={{
            marginTop: '15px',
            marginBottom: '10px',
          }}>Type of Destination:</label>
          <div style={{ height: '10px' }}></div>

          <FormGroup id="destinationRadios" selection={navigation.featureType}>
            <div>
              <input style={{ margin: '3px', }}
                type="radio"
                id={bbConstants.DESTINATION_PRODUCT}
                name="destination_types"
                onChange={this.handleDestinationTypeStateChange}
                value={bbConstants.DESTINATION_PRODUCT}
                checked={
                  navigation.featureType === bbConstants.DESTINATION_PRODUCT
                }
              />
              <label style={{ marginLeft: '5px' }}>Product</label>
              {space_10}


              <input style={{ margin: '3px', }}
                type="radio"
                id={bbConstants.DESTINATION_COLLECTION}
                name="destination_types"
                onChange={this.handleDestinationTypeStateChange}
                value={bbConstants.DESTINATION_COLLECTION}
                checked={
                  navigation.featureType === bbConstants.DESTINATION_COLLECTION
                }
              />
              <label style={{ marginLeft: '3px' }}>Collection</label>
              {space_10}

              <input style={{ margin: '3px', }}
                type="radio"
                id={bbConstants.DESTINATION_ALL_COLLECTION_BANNERS_SCREEN}
                name="destination_types"
                onChange={this.handleDestinationTypeStateChange}
                value={bbConstants.DESTINATION_ALL_COLLECTION_BANNERS_SCREEN}
                checked={
                  navigation.featureType ===
                  bbConstants.DESTINATION_ALL_COLLECTION_BANNERS_SCREEN
                }
              />
              <label style={{ marginLeft: '3px' }}>
                {bbConstants.ALL_COLLECTION_BANNERS_SCREEN_READABLE}
              </label>
              {space_10}

              <input style={{ margin: '3px', }}
                type="radio"
                id={bbConstants.DESTINATION_BANNERS}
                name="destination_types"
                onChange={this.handleDestinationTypeStateChange}
                value={bbConstants.DESTINATION_BANNERS}
                checked={
                  navigation.featureType === bbConstants.DESTINATION_BANNERS
                }
              />
              <label style={{ marginLeft: '3px' }}>Screen</label>
              {space_10}

              <input style={{ margin: '3px', }}
                type="radio"
                id={bbConstants.DESTINATION_SEARCH}
                name="destination_types"
                onChange={this.handleDestinationTypeStateChange}
                value={bbConstants.DESTINATION_SEARCH}
                checked={
                  navigation.featureType === bbConstants.DESTINATION_SEARCH
                }
              />
              <label style={{ marginLeft: '3px' }}>Search</label>
              {space_10}
            </div>

            {/* \\// Add a 'key' to gaurantee freshness? */}
            {selectedDestinationCard}
          </FormGroup>
        </div>
      </Card>
    );
  }
}

export default NavigationTab;
