import * as React from "react";

import { Button, Card } from '@mui/material';

import { nav_change_circle } from "../assets";
import { nav_coffee_maker } from "../assets";
import { nav_cognition } from "../assets";
import { nav_compost } from "../assets";
import { nav_cookie } from "../assets";
import { nav_cruelty_free } from "../assets";
import { nav_diamond } from "../assets";
import { nav_diversity_1 } from "../assets";
import { nav_diversity_2 } from "../assets";
import { nav_diversity_3 } from "../assets";
import { nav_eco } from "../assets";
import { nav_emoji_nature } from "../assets";
import { nav_emoji_objects } from "../assets";
import { nav_favorite } from "../assets";
import { nav_female } from "../assets";
import { nav_forest } from "../assets";
import { nav_groups } from "../assets";
import { nav_handshake } from "../assets";
import { nav_health_and_safety } from "../assets";
import { nav_home } from "../assets";
import { nav_local_cafe } from "../assets";
import { nav_male } from "../assets";
import { nav_military_tech } from "../assets";
import { nav_mood } from "../assets";
import { nav_neurology } from "../assets";
import { nav_person } from "../assets";
import { nav_pet_supplies } from "../assets";
import { nav_pets } from "../assets";
import { nav_potted_plant } from "../assets";
import { nav_psychiatry } from "../assets";
import { nav_psychology } from "../assets";
import { nav_psychology_alt } from "../assets";
import { nav_public } from "../assets";
import { nav_recycling } from "../assets";
import { nav_restaurant } from "../assets";
import { nav_rocket } from "../assets";
import { nav_rocket_launch } from "../assets";
import { nav_sale } from "../assets";
import { nav_sentiment_satisfied } from "../assets";
import { nav_sentiment_very_dissatisfied } from "../assets";
import { nav_settings } from "../assets";
import { nav_shopping_bag } from "../assets";
import { nav_skull } from "../assets";
import { nav_star } from "../assets";
import { nav_storefront } from "../assets";
import { nav_sunny } from "../assets";
import { nav_thumb_down } from "../assets";
import { nav_thumb_up } from "../assets";
import { nav_token } from "../assets";
import { nav_transgender } from "../assets";
import { nav_view_cozy } from "../assets";
import { nav_water_drop } from "../assets";
import { nav_workspace } from "../assets";

//  Popover Color Picker
class IconPickerCard extends React.Component {
  state = {
    showIconSelectionCard: false,
    iconId: this.props.iconId,
  };

  //  From - https://www.geeksforgeeks.org/how-to-pass-a-parameter-to-an-event-handler-or-callback/
  setIconId(iconId) {
    this.setState({
      iconId: iconId,
      showIconSelectionCard: false,
    });
    this.props.handleChangeImage(iconId);
  }

  showIconSelectionCard() {
    this.setState({ showIconSelectionCard: true });
  }

  hideIconSelectionCard() {
    this.setState({ showIconSelectionCard: false });
  }

  getIconImageFromIconId(iconId) {
    if (iconId === "nav_change_circle") {
      return nav_change_circle;
    } else if (iconId === "nav_coffee_maker") {
      return nav_coffee_maker;
    } else if (iconId === "nav_cognition") {
      return nav_cognition;
    } else if (iconId === "nav_compost") {
      return nav_compost;
    } else if (iconId === "nav_cookie") {
      return nav_cookie;
    } else if (iconId === "nav_cruelty_free") {
      return nav_cruelty_free;
    } else if (iconId === "nav_diamond") {
      return nav_diamond;
    } else if (iconId === "nav_diversity_1") {
      return nav_diversity_1;
    } else if (iconId === "nav_diversity_2") {
      return nav_diversity_2;
    } else if (iconId === "nav_diversity_3") {
      return nav_diversity_3;
    } else if (iconId === "nav_eco") {
      return nav_eco;
    } else if (iconId === "nav_emoji_nature") {
      return nav_emoji_nature;
    } else if (iconId === "nav_emoji_objects") {
      return nav_emoji_objects;
    } else if (iconId === "nav_favorite") {
      return nav_favorite;
    } else if (iconId === "nav_female") {
      return nav_female;
    } else if (iconId === "nav_forest") {
      return nav_forest;
    } else if (iconId === "nav_groups") {
      return nav_groups;
    } else if (iconId === "nav_handshake") {
      return nav_handshake;
    } else if (iconId === "nav_health_and_safety") {
      return nav_health_and_safety;
    } else if (iconId === "nav_home") {
      return nav_home;
    } else if (iconId === "nav_local_cafe") {
      return nav_local_cafe;
    } else if (iconId === "nav_male") {
      return nav_male;
    } else if (iconId === "nav_military_tech") {
      return nav_military_tech;
    } else if (iconId === "nav_mood") {
      return nav_mood;
    } else if (iconId === "nav_neurology") {
      return nav_neurology;
    } else if (iconId === "nav_person") {
      return nav_person;
    } else if (iconId === "nav_pet_supplies") {
      return nav_pet_supplies;
    } else if (iconId === "nav_pets") {
      return nav_pets;
    } else if (iconId === "nav_potted_plant") {
      return nav_potted_plant;
    } else if (iconId === "nav_psychiatry") {
      return nav_psychiatry;
    } else if (iconId === "nav_psychology") {
      return nav_psychology;
    } else if (iconId === "nav_psychology_alt") {
      return nav_psychology_alt;
    } else if (iconId === "nav_public") {
      return nav_public;
    } else if (iconId === "nav_recycling") {
      return nav_recycling;
    } else if (iconId === "nav_restaurant") {
      return nav_restaurant;
    } else if (iconId === "nav_rocket") {
      return nav_rocket;
    } else if (iconId === "nav_rocket_launch") {
      return nav_rocket_launch;
    } else if (iconId === "nav_sale") {
      return nav_sale;
    } else if (iconId === "nav_sentiment_satisfied") {
      return nav_sentiment_satisfied;
    } else if (iconId === "nav_sentiment_very_dissatisfied") {
      return nav_sentiment_very_dissatisfied;
    } else if (iconId === "nav_settings") {
      return nav_settings;
    } else if (iconId === "nav_shopping_bag") {
      return nav_shopping_bag;
    } else if (iconId === "nav_skull") {
      return nav_skull;
    } else if (iconId === "nav_star") {
      return nav_star;
    } else if (iconId === "nav_storefront") {
      return nav_storefront;
    } else if (iconId === "nav_sunny") {
      return nav_sunny;
    } else if (iconId === "nav_thumb_down") {
      return nav_thumb_down;
    } else if (iconId === "nav_thumb_up") {
      return nav_thumb_up;
    } else if (iconId === "nav_token") {
      return nav_token;
    } else if (iconId === "nav_transgender") {
      return nav_transgender;
    } else if (iconId === "nav_view_cozy") {
      return nav_view_cozy;
    } else if (iconId === "nav_water_drop") {
      return nav_water_drop;
    } else if (iconId === "nav_workspace") {
      return nav_workspace;
    }
  }

  // Returns the UI
  render() {
    const buttonStyle = {
      margin: '5px',
      border: '1px solid #000', // Set border to create an outlined effect
      borderRadius: '5px', // Add border-radius for rounded corners
    };


    var iconSelectionCard = null;
    var changeIconButton = null;
    if (this.state.showIconSelectionCard) {
      iconSelectionCard = (

        <div>
          <br></br>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_change_circle")}>
            <img src={nav_change_circle} alt="Change Circle" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_coffee_maker")}>
            <img src={nav_coffee_maker} alt="Coffee Maker" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_cognition")}>
            <img src={nav_cognition} alt="Cognition" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_compost")}>
            <img src={nav_compost} alt="Compost" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_cookie")}>
            <img src={nav_cookie} alt="Cookie" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_cruelty_free")}>
            <img src={nav_cruelty_free} alt="Cruelty Free" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_diamond")}>
            <img src={nav_diamond} alt="Diamond" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_diversity_1")}>
            <img src={nav_diversity_1} alt="Diversity 1" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_diversity_2")}>
            <img src={nav_diversity_2} alt="Diversity 2" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_diversity_3")}>
            <img src={nav_diversity_3} alt="Diversity 3" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_eco")}>
            <img src={nav_eco} alt="Eco" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_emoji_nature")}>
            <img src={nav_emoji_nature} alt="Emoji Nature" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_emoji_objects")}>
            <img src={nav_emoji_objects} alt="Emoji Objects" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_favorite")}>
            <img src={nav_favorite} alt="Favorite" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_female")}>
            <img src={nav_female} alt="Female" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_forest")}>
            <img src={nav_forest} alt="Forest" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_groups")}>
            <img src={nav_groups} alt="Groups" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_handshake")}>
            <img src={nav_handshake} alt="Handshake" />
          </button>
          <button style={buttonStyle}
            onClick={this.setIconId.bind(this, "nav_health_and_safety")}
          >
            <img src={nav_health_and_safety} alt="Health and Safety" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_home")}>
            <img src={nav_home} alt="Home" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_local_cafe")}>
            <img src={nav_local_cafe} alt="Local Cafe" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_male")}>
            <img src={nav_male} alt="Male" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_military_tech")}>
            <img src={nav_military_tech} alt="Military Tech" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_mood")}>
            <img src={nav_mood} alt="Mood" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_neurology")}>
            <img src={nav_neurology} alt="Neurology" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_person")}>
            <img src={nav_person} alt="Person" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_pet_supplies")}>
            <img src={nav_pet_supplies} alt="Pet Supplies" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_pets")}>
            <img src={nav_pets} alt="Pets" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_potted_plant")}>
            <img src={nav_potted_plant} alt="Potted Plant" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_psychiatry")}>
            <img src={nav_psychiatry} alt="Psychiatry" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_psychology")}>
            <img src={nav_psychology} alt="Psychology" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_psychology_alt")}>
            <img src={nav_psychology_alt} alt="Psychology Alt" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_public")}>
            <img src={nav_public} alt="Public" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_recycling")}>
            <img src={nav_recycling} alt="Recycling" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_restaurant")}>
            <img src={nav_restaurant} alt="Restaurant" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_rocket")}>
            <img src={nav_rocket} alt="Rocket" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_rocket_launch")}>
            <img src={nav_rocket_launch} alt="Rocket Launch" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_sale")}>
            <img src={nav_sale} alt="Sell" />
          </button>
          <button style={buttonStyle}
            onClick={this.setIconId.bind(this, "nav_sentiment_satisfied")}
          >
            <img src={nav_sentiment_satisfied} alt="Sentiment Satisfied" />
          </button>
          <button style={buttonStyle}
            onClick={this.setIconId.bind(
              this,
              "nav_sentiment_very_dissatisfied"
            )}
          >
            <img
              src={nav_sentiment_very_dissatisfied}
              alt="Sentiment Very Dissatisfied"
            />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_settings")}>
            <img src={nav_settings} alt="Settings" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_shopping_bag")}>
            <img src={nav_shopping_bag} alt="Shopping Bag" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_skull")}>
            <img src={nav_skull} alt="Skull" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_star")}>
            <img src={nav_star} alt="Star" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_storefront")}>
            <img src={nav_storefront} alt="Storefront" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_sunny")}>
            <img src={nav_sunny} alt="Sunny" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_thumb_down")}>
            <img src={nav_thumb_down} alt="Thumb Down" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_thumb_up")}>
            <img src={nav_thumb_up} alt="Thumb Up" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_token")}>
            <img src={nav_token} alt="Token" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_transgender")}>
            <img src={nav_transgender} alt="Transgender" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_view_cozy")}>
            <img src={nav_view_cozy} alt="View Cozy" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_water_drop")}>
            <img src={nav_water_drop} alt="Water Drop" />
          </button>
          <button style={buttonStyle} onClick={this.setIconId.bind(this, "nav_workspace")}>
            <img src={nav_workspace} alt="Workspace Premium" />
          </button>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

          <Button
            size="small"
            variant="contained"
            style={{ marginLeft: '25px', marginBottom: '25px' }}
            onClick={this.hideIconSelectionCard.bind(this)}>
            Cancel
          </Button>
          <br></br>
          &nbsp;
        </div>

      );
    } else {
      changeIconButton =
        <Button
          size="small"
          variant="contained"
          style={{ marginLeft: '200px', marginTop: '-50px' }}
          onClick={this.showIconSelectionCard.bind(this)}
        >
          Change Icon
        </Button>
    }

    return (
      <div style={{ width: '450px' }}>
        <div style={{ marginLeft: '30px' }}>
          <img
            style={buttonStyle}
            src={this.getIconImageFromIconId(this.state.iconId)}
            alt="Tab Icon"
          />
          {changeIconButton}
        </div>
        {iconSelectionCard}
      </div>
    );
  }
}

export default IconPickerCard;
