import * as React from "react";

import * as bbConstants from "./constants/bb-constants";

import CarouselsResourceList from "./CarouselsResourceList";
import AddEditCarousel from "./AddEditCarousel";

import { BASE_URL } from "./constants/endpointDefinition";


export const display_mode_availableCarousels =
  "display_mode-available-carousels";
export const display_mode_editCarousel = "display_mode-edit-carousel";


class CarouselBuilder extends React.Component {
  state = {
    shopID: this.props.shopID,
    shopValue: this.props.shopValue,
    availableCarousels: this.props.availableCarousels,

    displayMode: display_mode_availableCarousels,

    selectedCarousel: this.props.selectedCarousel,
    selectedAutoRotateOption: [bbConstants.AUTO_ROTATE_DELAY_DISABLED],
    carouselName: "carousel name",
    destinations: [],
    carouselSize: bbConstants.BANNER_MEDIUM_READABLE,
  };

  //
  setSelectedCarousel = (e) => {
    var selectedCarousel = e;

    var selectedAutoRotateOption;
    var carouselName;
    var destinations;
    var carouselSize;

    if (null != e.item) {
      selectedAutoRotateOption =
        selectedCarousel.item.autoRotateMilliSecondsPause;
      carouselName = selectedCarousel.item.carouselName;
      destinations = selectedCarousel.item.destinations;
      carouselSize = selectedCarousel.item.bannerSize;
    } else {
      selectedAutoRotateOption = selectedCarousel.autoRotateMilliSecondsPause;
      carouselName = selectedCarousel.carouselName;
      destinations = selectedCarousel.destinations;
      carouselSize = selectedCarousel.bannerSize;
    }

    this.setState({
      selectedCarousel,
      selectedAutoRotateOption,
      carouselName,
      destinations,
      carouselSize,
      displayMode: display_mode_editCarousel,
    });
  };

  //
  updateAvailableCarousels = (availableCarousels) => {
    this.setState({ availableCarousels });
    this.props.updateAvailableCarousels(availableCarousels);
  };

  //
  showAvailableCarousels = (e) => {
    var displayMode = display_mode_availableCarousels;
    this.setState({ displayMode });
  };

  //
  deleteCarousel = (carousel) => {
    var carouselId = carousel.carouselId;
    var shopID = this.state.shopID;

    var payload = {
      shopID: shopID,
      carouselId: carouselId,
    };

    // ///////    Post to API   //////////////////////////////////////////////
    // Creating a XHR object
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/deleteCarouselByIdForShop";

    // *** Deleting the Carousel from memory
    // - this should be executed AFTER the response confirms the deleting on the server
    // currenty getting an error when trying to change this.state in that block :(
    let availableCarousels = this.state.availableCarousels;
    let newAvailableCarousels = [];
    for (const carousel of availableCarousels) {
      if (carousel.carouselId === carouselId) {
        // nop
      } else {
        newAvailableCarousels.push(carousel);
      }
    }
    this.setState({ availableCarousels: newAvailableCarousels });
    this.props.updateAvailableCarousels(newAvailableCarousels); // tell parent
    // *** Deleting the Carousel from memory

    // open a connection
    xhr.open("POST", url, true);

    // Set the request header i.e. which type of content you are sending
    xhr.setRequestHeader("Content-Type", "application/json");

    // Create a state change callback
    xhr.onreadystatechange = function () {
      // message when we're done
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          alert("Successfully Deleted Carousel");
        } else {
          alert("Error!  Something went wrong when deleting the Carousel");
        }
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);

    // Sending data with the request
    xhr.send(data);
  };

  
  //
  render() {
    // 'auto roatate' options
    var delayValueLabels = [];
    var delayValues = bbConstants.AUTO_ROTATE_DELAY_CONSTANTS;
    for (const dv of delayValues) {
      delayValueLabels.push(dv.label);
    }

    // touching the state?
    var availableCarousels = this.state.availableCarousels;

    if (this.state.displayMode === display_mode_availableCarousels) {
      return (
        <div>
          <CarouselsResourceList
            id="carouselsResourceList-id"
            shopValue={this.state.shopValue}
            availableCarousels={availableCarousels}
            setSelectedCarousel={this.setSelectedCarousel}
            deleteCarousel={this.deleteCarousel}
          />
        </div>
      );
    } else if (this.state.displayMode === display_mode_editCarousel) {
      return (
        <div>
          <AddEditCarousel
            shopID={this.state.shopID}
            shopValue={this.state.shopValue}
            selectedCarousel={this.state.selectedCarousel}
            selectedAutoRotateOption={this.state.selectedAutoRotateOption}
            carouselName={this.state.carouselName}
            destinations={this.state.destinations}
            carouselSize={this.state.carouselSize}
            availableCarousels={this.state.availableCarousels}
            updateAvailableCarousels={this.updateAvailableCarousels}
            showAvailableCarousels={this.showAvailableCarousels}
          ></AddEditCarousel>
        </div>
      );
    }
  }

  // get the autorotate 'value' (number) for this 'label'
  getAutoRotateMilliSecondsPause(autoRotateSpeedLabel) {
    var autoRotateMilliSecondsPause = 0;
    var delayValues = bbConstants.AUTO_ROTATE_DELAY_CONSTANTS;
    for (const dv of delayValues) {
      if (dv.label === autoRotateSpeedLabel) {
        autoRotateMilliSecondsPause = dv.autoRotateMilliSecondsPause;
        break;
      }
    }
    return autoRotateMilliSecondsPause;
  }
}

export default CarouselBuilder;
