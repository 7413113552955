import * as React from "react";

import {
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Link,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input'

import * as bbConstants from "./constants/bb-constants";

import { font_arial } from "../assets";
import { font_caveat_variable } from "../assets";
import { font_century_gothic } from "../assets";
import { font_futura_light } from "../assets";
import { font_futura_medium } from "../assets";
import { font_helvetica_light } from "../assets";
import { font_helvetica_neue } from "../assets";
import { font_helvetica } from "../assets";
import { font_lugrasimo_regular } from "../assets";
import { font_roboto_medium } from "../assets";
import { font_shadowsIntoLight_regular } from "../assets";


class ConfigVariables extends React.Component {
  state = {
    dirty: 1,
    shopID: this.props.shopID,
    configVariables: this.props.shopifyConfigVariables,

    configText: "",
    parsedColors: {},

    statusBarColor: this.getStatusBarColorFromConfigVariables(
      this.props.shopifyConfigVariables
    ),

    selectedFontOption: this.getFontNameFromConfigVariables(
      this.props.shopifyConfigVariables
    ),
  };

  //////
  // get the autorotate 'label' for this 'value'
  getFontId(fontLabel) {
    var fontId = "fontId";
    var fontValues = bbConstants.FONT_CONSTANTS;

    for (const fv of fontValues) {
      if (fv.label === fontLabel) {
        fontId = fv.fontId;
        break;
      }
    }
    return fontId;
  }


  getFontPreviewImage(fontId) {
    if (fontId === "Arial") {
      return font_arial;
    } else if (fontId === "Caveat Variable") {
      return font_caveat_variable;
    } else if (fontId === "Century Gothic") {
      return font_century_gothic;
    } else if (fontId === "Futura Light") {
      return font_futura_light;
    } else if (fontId === "Futura Medium") {
      return font_futura_medium;
    } else if (fontId === "Helvetica Light") {
      return font_helvetica_light;
    } else if (fontId === "Helvetica Neue") {
      return font_helvetica_neue;
    } else if (fontId === "Helvetica") {
      return font_helvetica;
    } else if (fontId === "Lugrasimo-Regular") {
      return font_lugrasimo_regular;
    } else if (fontId === "Roboto-Medium") {
      return font_roboto_medium;
    } else if (fontId === "ShadowsIntoLight-Regular") {
      return font_shadowsIntoLight_regular;
    }
  }


  getStatusBarColorFromConfigVariables(configVariables) {
    let useBlackStatusBarTextColor =
      configVariables["use_black_statusBar_text_color"];
    var statusBarColor = bbConstants.STATUS_BAR_TEXT_COLOR_WHITE;
    if (
      useBlackStatusBarTextColor === true ||
      useBlackStatusBarTextColor === "true" ||
      useBlackStatusBarTextColor === "True"
    ) {
      statusBarColor = bbConstants.STATUS_BAR_TEXT_COLOR_BLACK;
    }
    return statusBarColor;
  }

  getFontNameFromConfigVariables(configVariables) {
    var fontId = configVariables["font"];
    var fontValues = bbConstants.FONT_CONSTANTS;
    var fontName = "default";
    for (const fv of fontValues) {
      if (fv.fontId === fontId) {
        fontName = fv.label;
        break;
      }
    }
    return fontName;
  }

  //  Remember color changes in State
  propogateColorChangeWithKey = (e) => {
    var key = e.key;
    var value = e.value;
    var configVariables = this.state.configVariables;
    configVariables[key] = value;
    var dark_key = "dark_" + key; // duplicate "dark" mode color
    configVariables[dark_key] = value;
    this.setState({ configVariables });
    this.props.updateConfigVariables(configVariables);
  };

  //  remember the JSON String (paste from the settings_schema.json)
  setConfigText = (e) => {
    var configText = e.target.value;
    this.setState({ configText });
  };

  //
  processConfigText = (e) => {
    this.parseConfigText(e);
    this.changeConfigVariables();
  };

  //  Apply parsed config variables to the ConfigVariables object
  changeConfigVariables() {
    var parsedColors = this.state.parsedColors;
    var configVariables = this.state.configVariables;

    // background colors
    var newValue = parsedColors["color_body_bg"];
    if (newValue === undefined) {
      newValue = parsedColors["color_background"];
    }
    if (newValue !== undefined) {
      configVariables["color_top_bar_background"] = newValue;
      configVariables["color_background"] = newValue;
      configVariables["color_nav_bar"] = newValue;

      configVariables["dark_color_top_bar_background"] = newValue;
      configVariables["dark_color_background"] = newValue;
      configVariables["dark_color_nav_bar"] = newValue;
    }

    // button background color
    newValue = parsedColors["color_button"];
    if (newValue !== undefined) {
      configVariables["color_cta_background"] = newValue;
      configVariables["dark_color_cta_background"] = newValue;
    }

    // action bar text color
    newValue = parsedColors["color_text_field"];
    if (newValue !== undefined) {
      configVariables["color_top_bar_text"] = newValue;
      configVariables["dark_color_top_bar_text"] = newValue;
    }

    // text colors
    newValue = parsedColors["color_body_text"];
    if (newValue !== undefined) {
      configVariables["color_cta_text"] = newValue; //
      configVariables["color_navigation_button_tint"] = newValue;

      configVariables["dark_color_cta_text"] = newValue; //
      configVariables["dark_color_navigation_button_tint"] = newValue;
    }

    // CTA/button text  --  might override value set above /\
    newValue = parsedColors["color_button_text"];
    if (newValue !== undefined) {
      configVariables["color_cta_text"] = newValue;
      configVariables["dark_color_cta_text"] = newValue;
    }

    // more text colors  --  these feel wonky
    newValue = parsedColors["color_body_text"];
    if (newValue !== undefined) {
      configVariables["color_primary_text"] = newValue;
      configVariables["dark_color_primary_text"] = newValue;
    }

    // ***  Obsolete  *** //
    // secondary text color
    // newValue = parsedColors["color_bg"];
    // if (newValue != undefined) {
    //   configVariables["color_secondary_text"] = newValue;
    //   configVariables["dark_color_secondary_text"] = newValue;
    // }

    //  On sale text
    newValue = parsedColors["color_sale_tag"];
    if (newValue === undefined) {
      newValue = parsedColors["color_sale_price"];
    }
    if (newValue === undefined) {
      newValue = parsedColors["color_sale_text"];
    }
    if (newValue !== undefined) {
      configVariables["color_on_sale_text"] = newValue;
      configVariables["dark_color_on_sale_text"] = newValue;
    }

    // Seperator
    newValue = parsedColors["color_borders"];
    if (newValue === undefined) {
      newValue = parsedColors["color_borders_and_lines"];
    }
    if (newValue !== undefined) {
      configVariables["color_separator"] = newValue;
      configVariables["dark_color_separator"] = newValue;
    }

    // klunky way to dirty the state to cause re-render
    var dirty = this.state.dirty;
    dirty = dirty + 1;
    this.setState({ dirty, configVariables });
    this.props.updateConfigVariables(configVariables);
  }

  parseConfigText = (e) => {
    var configText = this.state.configText;

    try {
      var parsedConfigValues = JSON.parse(configText);
      var parsedColors = this.state.parsedColors;
      this.traverse(parsedConfigValues, parsedColors);
    } catch (e) {
      // TODO - Error message to User
      // console.log("parseConfigText : Error parsing configText : " + e);
    }

    parsedColors = this.state.parsedColors;
    var numberOfColorsFound = Object.keys(parsedColors).length;

    if (numberOfColorsFound === 0) {
      try {
        parsedConfigValues = JSON.parse(configText);
        this.dataTraverse(parsedConfigValues, parsedColors);
      } catch (e) {
        // TODO - Error message to User
      }
    }
  };

  // method that crawls the JSON Tree looking for objects of type 'color'
  dataTraverse(o, parsedColors) {
    for (var i in o) {
      if (o[i] !== null && typeof o[i] == "object") {
        var obj = o[i];
        var lightObj = null;
        try {
          Object.entries(obj).forEach(([key, value]) => {
            if (key.startsWith("presets") || key.startsWith("Light")) {
              lightObj = obj;
            }

            if (key.startsWith("color")) {
              parsedColors[key] = value.toUpperCase();
              this.setState({ parsedColors });
            }
          });
        } catch (e) {
          // console.log("dataTraverse error - " + e);
        }

        if (o[i] === lightObj) {
          // console.log("matched on the lightObj");
        } else {
          this.dataTraverse(o[i], parsedColors);
        }
      }
    }
  }

  // method that crawls the JSON Tree looking for objects of type 'color'
  traverse(o, parsedColors) {
    for (var i in o) {
      if (o[i] !== null && typeof o[i] == "object") {
        var obj = o[i];

        try {
          if (obj.type === "color") {
            var id = obj.id;
            var value = obj.default.toUpperCase();
            parsedColors[id] = value;
            this.setState({ parsedColors });
          }
        } catch (e) {
          // console.log("traverse error - " + e);
        }
        this.traverse(o[i], parsedColors);
      }
    }
  }

  //
  chooseFileButtonClicked = (e) => {
    const fileElem = document.getElementById("file-input");
    if (fileElem) {
      fileElem.click();
    }
  };

  //
  readSingleFile = (e) => {
    // clear parsedColors in case a file was previously parsed
    var parsedColors = {};
    this.setState({ parsedColors });

    if (e === undefined) {
      return;
    }

    var file = e.target.files[0];
    if (!file) {
      return;
    }
    var reader = new FileReader();
    const scope = this;
    reader.onload = function (e) {
      var contents = e.target.result;
      var configText = contents;
      var dirty = scope.state.dirty;
      dirty = dirty + 1;
      scope.setState({ configText, dirty });
      scope.processConfigText(e);
    };
    reader.readAsText(file);
  };

  //
  handleFontChange = (fontName) => {
    var fontId = this.getFontId(fontName.target.value);
    
    var configVariables = this.state.configVariables;
    configVariables["font"] = fontId;
    this.setState({
      configVariables,
      selectedFontOption: fontName.target.value,
    });
    this.props.updateConfigVariables(configVariables);
  };

  /////////
  //  Destination Type Radio Buttons
  handleStatusBarColorStateChange = (e) => {
    var statusBarColor = e.target.value;

    var use_black_statusBar_text_color = true;
    if (statusBarColor === bbConstants.STATUS_BAR_TEXT_COLOR_WHITE) {
      use_black_statusBar_text_color = false;
    }
    var configVariables = this.state.configVariables;
    configVariables["use_black_statusBar_text_color"] =
      use_black_statusBar_text_color;

    this.setState({ statusBarColor, configVariables });
  };


  //
  // handleColorPickerChange = (e, x, y) => {
  handleColorPickerChange = (color, configVariableKey) => {
    var key = configVariableKey;
    var value = color;

    var configVariables = this.state.configVariables;
    configVariables[key] = value;
    var dark_key = "dark_" + key; // duplicate "dark" mode color
    configVariables[dark_key] = value;
    this.setState({ configVariables });
    this.props.updateConfigVariables(configVariables);

  }


  //
  render() {
    // 'font' options
    var fontLabels = [];
    var fontValues = bbConstants.FONT_CONSTANTS;
    for (const fv of fontValues) {
      fontLabels.push(fv.label);
    }
    var fontPreviewImage = this.getFontPreviewImage(this.state.selectedFontOption)


    // color picker style
    const colorInputStyle = {
      padding: '5px',
      margin: '5px'
    };


    return (
      <Card key={this.state.dirty}>

        <Card>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label
              style={{
                paddingLeft: '20px',
                paddingTop: '10px',
                marginRight: 'auto',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}>
              Theme Colors:
            </label>

            <Link
              external="true"
              url="https://appassets.s3.us-east-2.amazonaws.com/Shopify+App/Color+Variables+Guide+2.png"
              style={{
                marginLeft: 'auto',
                paddingRight: '20px',
                paddingTop: '10px',
              }}
            >
              Color Variables Guide
            </Link>
          </div>

          <br></br>

          <MuiColorInput
            style={colorInputStyle}
            label="Top bar background"
            format="hex"
            onChange={(color) => this.handleColorPickerChange(color, "color_top_bar_background")}
            value={this.state.configVariables.color_top_bar_background}
          />

          <MuiColorInput
            style={colorInputStyle}
            label="Top bar Text"
            format="hex"
            onChange={(color) => this.handleColorPickerChange(color, "color_top_bar_text")}
            value={this.state.configVariables.color_top_bar_text}
          />

          <div>
            <MuiColorInput
              style={colorInputStyle}
              label="Bottom Bar - Background"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_nav_bar")}
              value={this.state.configVariables.color_nav_bar}
            />

            <MuiColorInput
              style={colorInputStyle}
              label="Bottom Bar - Selected Tab"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_navigation_button_tint")}
              value={
                this.state.configVariables.color_navigation_button_tint
              }
            />
          </div>

          <div>
            <MuiColorInput
              style={colorInputStyle}
              label="Bottom Bar - UN-selected Tabs"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_navigation_button_unselected")}
              value={
                this.state.configVariables.color_navigation_button_unselected
              }
            />

            <MuiColorInput
              style={colorInputStyle}
              label="Background"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_background")}
              value={this.state.configVariables.color_background}
            />
          </div>

          <div>
            <MuiColorInput
              style={colorInputStyle}
              label="Button Background"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_cta_background")}
              value={this.state.configVariables.color_cta_background}
            />

            <MuiColorInput
              style={colorInputStyle}
              label="Button Text"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_cta_text")}
              value={this.state.configVariables.color_cta_text}
            />
          </div>

          <div>
            <MuiColorInput
              style={colorInputStyle}
              label="'On Sale' text"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_on_sale_text")}
              value={this.state.configVariables.color_on_sale_text}
            />

            <MuiColorInput
              style={colorInputStyle}
              label="Primary text"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_primary_text")}
              value={this.state.configVariables.color_primary_text}
            />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start', // Corrected
              flexDirection: 'row', // Corrected
            }}
          >
            <MuiColorInput
              style={colorInputStyle}
              label="Separators"
              format="hex"
              onChange={(color) => this.handleColorPickerChange(color, "color_separator")}
              value={this.state.configVariables.color_separator}
            />

            <div
              style={{
                width: '225px',
                padding: '5px',
                margin: '5px',
                alignItems: 'left',
                justifyContent: 'left',
              }}>
              <Card variant="outlined">
                <label style={{
                  paddingLeft: '5px',
                  paddingTop: '10px',
                  textDecoration: 'underline',
                }}>
                  Status bar text color:
                </label>

                <br></br>

                <input
                  type="radio"
                  id={bbConstants.STATUS_BAR_TEXT_COLOR_BLACK}
                  name="status_bar_text_colors"
                  onChange={this.handleStatusBarColorStateChange}
                  value={bbConstants.STATUS_BAR_TEXT_COLOR_BLACK}
                  checked={
                    this.state.statusBarColor ===
                    bbConstants.STATUS_BAR_TEXT_COLOR_BLACK
                  }
                />
                <label>Black</label>
                <br></br>

                <input
                  type="radio"
                  id={bbConstants.STATUS_BAR_TEXT_COLOR_WHITE}
                  name="status_bar_text_colors"
                  onChange={this.handleStatusBarColorStateChange}
                  value={bbConstants.STATUS_BAR_TEXT_COLOR_WHITE}
                  checked={
                    this.state.statusBarColor ===
                    bbConstants.STATUS_BAR_TEXT_COLOR_WHITE
                  }
                />
                <label>White</label>

              </Card>
            </div>
          </div>

        </Card>

        <br></br>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '0px',
            padding: '5px'
          }}>

          <FormControl style={{ margin: '15px' }}>
            <label
              style={{
                display: 'flex',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}>
              Font:
            </label>
            <br></br>
            <img 
              src={fontPreviewImage} 
              style={{ marginTop: '-15px' }}
              width={400} 
              alt="Font Preview" 
            />
            
            <RadioGroup
              value={this.state.selectedFontOption}
              onChange={this.handleFontChange}
            >
              {fontLabels.map((fontLabel, index) => (
                <FormControlLabel
                  key={fontLabel}
                  value={fontLabel}
                  control={<Radio />}
                  label={fontLabel}
                  style={{ margin: '-5px 0' }}
                />
              ))}
            </RadioGroup>
          </FormControl>

        </Card>

      </Card>
    );
  }
}

export default ConfigVariables;
