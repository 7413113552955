// storage
export const STORAGE_NAME_PRODUCT_IDS = "productIds-dev";
export const STORAGE_NAME_COLLECTION_IDS = "collectionIds-dev";
// export const STORAGE_NAME_HOME_SELECTED_COLLECTIONS = "home-selected-collections-dev";
export const STORAGE_NAME_SHOP_SELECTED_COLLECTIONS =
  "shop-selected-collections-dev";

export const STORAGE_NAME_BANNER_BUILDER_SELECTED_COLLECTIONS =
  "selected-collections-dev";

// ??  Commented out for import to app-builder
// export const STORAGE_NAME_DESTINATIONS = "all-banners-dev";

// Destination Constants used by Apps
export const DESTINATION_COLLECTION = "DESTINATION_COLLECTION";
export const DESTINATION_PRODUCT = "DESTINATION_PRODUCT";
export const DESTINATION_NONE = "DESTINATION_NONE";
export const DESTINATION_ALL_COLLECTION_BANNERS_SCREEN =
  "DESTINATION_ALL_COLLECTION_BANNERS_SCREEN";
export const DESTINATION_BANNERS = "DESTINATION_BANNERS";
export const DESTINATION_PLP = "DESTINATION_PLP";
export const DESTINATION_SEARCH = "DESTINATION_SEARCH";
export const DESTINATION_URL = "DESTINATION_URL";

// Destination Constants - human readable
export const DESTINATION_COLLECTION_READABLE =
  "List of Products in a Collection";
export const DESTINATION_PRODUCT_READABLE = "Product Details Page";
export const ALL_COLLECTION_BANNERS_SCREEN_READABLE =
  "A Banner to Each Collection";
export const DESTINATION_BANNERS_READABLE = "Customized Screen";
export const DESTINATION_PLP_READABLE = "List of Products";
export const DESTINATION_SEARCH_PLP_READABLE = "List of Products from a Search";

//  Banner Sizes
export const BANNER_PENCIL = "BANNER_PENCIL";
export const BANNER_SMALL = "BANNER_SMALL";
export const BANNER_MEDIUM = "BANNER_MEDIUM";
export const BANNER_TALL = "BANNER_TALL";

export const BANNER_PENCIL_READABLE = "Pencil";
export const BANNER_SMALL_READABLE = "Small";
export const BANNER_MEDIUM_READABLE = "Medium";
export const BANNER_TALL_READABLE = "Tall";

export const BANNER_PENCIL_DESCRIPTION = "Recommend 500px wide, 100px tall";
export const BANNER_SMALL_DESCRIPTION = "Recommend 300px wide, 100px tall";
export const BANNER_MEDIUM_DESCRIPTION = "Recommend 400px wide, 200px tall";
export const BANNER_TALL_DESCRIPTION = "Recommend 500px wide, 500px tall";

export const BANNER_PENCIL_EXAMPLE_URL =
  "https://appassets.s3.us-east-2.amazonaws.com/Shopify+App/banner+examples/pencil+banner+example.png";
export const BANNER_SMALL_EXAMPLE_URL =
  "https://appassets.s3.us-east-2.amazonaws.com/Shopify+App/banner+examples/small+banner+example.png";
export const BANNER_MEDIUM_EXAMPLE_URL =
  "https://appassets.s3.us-east-2.amazonaws.com/Shopify+App/banner+examples/medium+banner+example.png";
export const BANNER_TALL_EXAMPLE_URL =
  "https://appassets.s3.us-east-2.amazonaws.com/Shopify+App/banner+examples/tall+banner+example.png";

export const BANNER_SIZE_OPTIONS_CONSTANTS = [
  { label: BANNER_PENCIL_READABLE, value: BANNER_PENCIL },
  { label: BANNER_SMALL_READABLE, value: BANNER_SMALL },
  { label: BANNER_MEDIUM_READABLE, value: BANNER_MEDIUM },
  { label: BANNER_TALL_READABLE, value: BANNER_TALL },
];

export const BANNER_SIZE_DESCRIPTIONS_CONSTANTS = [
  {
    label: BANNER_PENCIL,
    value: BANNER_PENCIL_DESCRIPTION,
    url: BANNER_PENCIL_EXAMPLE_URL,
    width: "500px",
    height: "100px",
  },
  {
    label: BANNER_SMALL,
    value: BANNER_SMALL_DESCRIPTION,
    url: BANNER_SMALL_EXAMPLE_URL,
    width: "300",
    height: "100",
  },
  {
    label: BANNER_MEDIUM,
    value: BANNER_MEDIUM_DESCRIPTION,
    url: BANNER_MEDIUM_EXAMPLE_URL,
    width: 200,
    height: 100,
  },
  {
    label: BANNER_TALL,
    value: BANNER_TALL_DESCRIPTION,
    url: BANNER_TALL_EXAMPLE_URL,
    width: "100px",
    height: "100px",
  },
];

export const HOME_PAGE_BANNER_SIZE_OPTIONS = [
  { label: "Small", value: BANNER_SMALL },
  { label: "Medium", value: BANNER_MEDIUM },
  { label: "Tall", value: BANNER_TALL },
];

export const SHOP_SECTION_BANNER_SIZE_OPTIONS = [
  { label: "Small", value: BANNER_SMALL },
  { label: "Medium", value: BANNER_MEDIUM },
  { label: "Tall", value: BANNER_TALL },
];

// Auto rotate
export const AUTO_ROTATE_DELAY_DISABLED = "Disable Auto Rotate";
export const AUTO_ROTATE_DELAY_CONSTANTS = [
  { label: AUTO_ROTATE_DELAY_DISABLED, autoRotateMilliSecondsPause: -1 },
  { label: "0.5 second", autoRotateMilliSecondsPause: 0.5 },
  { label: "1 second", autoRotateMilliSecondsPause: 1 },
  { label: "1.5 seconds", autoRotateMilliSecondsPause: 1.5 },
  { label: "2 seconds", autoRotateMilliSecondsPause: 2 },
  { label: "2.5 seconds", autoRotateMilliSecondsPause: 2.5 },
  { label: "3 seconds", autoRotateMilliSecondsPause: 3 },
  { label: "4 seconds", autoRotateMilliSecondsPause: 4 },
  { label: "5 seconds", autoRotateMilliSecondsPause: 5 },
  { label: "6 seconds", autoRotateMilliSecondsPause: 6 },
  { label: "7 seconds", autoRotateMilliSecondsPause: 7 },
  { label: "8 seconds", autoRotateMilliSecondsPause: 8 },
  { label: "9 seconds", autoRotateMilliSecondsPause: 9 },
  { label: "10 seconds", autoRotateMilliSecondsPause: 10 },
];

// Fonts
export const FONT_DEFAULT_ROBOTO = "Roboto-Medium";
export const FONT_CONSTANTS = [
  { label: "Arial", fontId: "arial" },
  { label: "Caveat Variable", fontId: "caveat_variable" },
  { label: "Century Gothic", fontId: "century_gothic" },
  { label: "Futura Light", fontId: "futura_light" },
  { label: "Futura Medium", fontId: "futura_medium" },
  { label: "Helvetica", fontId: "helvetica" },
  { label: "Helvetica Light", fontId: "helvetica_light" },
  { label: "Helvetica Neue", fontId: "helvetica_neue" },
  { label: "Lugrasimo-Regular", fontId: "lugrasimo_regular" },
  { label: "Roboto-Medium", fontId: "roboto_medium" },
  { label: "ShadowsIntoLight-Regular", fontId: "shadows_into_light_regular" },
];

//  Home Page Builder
export const BANNER_SCREEN_ALL_COLLECTIONS_ALWAYS =
  "BANNER_SCREEN_ALL_COLLECTIONS_ALWAYS";
export const BANNER_SCREEN_SELECT_COLLECTIONS =
  "BANNER_SCREEN_SELECT_COLLECTIONS";
export const BANNER_SCREEN_CUSTOMIZE = "BANNER_SCREEN_CUSTOMIZE";
export const HOME_PAGE_SELECTOR_OTIONS = [
  {
    label: "Banner for Each Collections",
    value: BANNER_SCREEN_ALL_COLLECTIONS_ALWAYS,
  },
  { label: "Select Collections", value: BANNER_SCREEN_SELECT_COLLECTIONS },
  { label: "Build a Page of Banners", value: BANNER_SCREEN_CUSTOMIZE },
];

// Status Bar Text Color
export const STATUS_BAR_TEXT_COLOR_BLACK = "STATUS_BAR_TEXT_COLOR_BLACK";
export const STATUS_BAR_TEXT_COLOR_WHITE = "STATUS_BAR_TEXT_COLOR_WHITE";

//  Shop Section Builder
export const SHOP_SECTION_ALL_COLLECTIONS_ALWAYS =
  "SHOP_SECTION_ALL_COLLECTIONS_ALWAYS";
export const SHOP_SECTION_SELECT_COLLECTIONS =
  "SHOP_SECTION_SELECT_COLLECTIONS";
export const SHOP_SECTION_CUSTOMIZE = "SHOP_SECTION_CUSTOMIZE";
export const SHOP_SECTION_SELECTOR_OTIONS = [
  {
    label: "Always show ALL Collections",
    value: SHOP_SECTION_ALL_COLLECTIONS_ALWAYS,
  },
  { label: "Select Collections", value: SHOP_SECTION_SELECT_COLLECTIONS },
  { label: "Build a Page of Carousels", value: SHOP_SECTION_CUSTOMIZE },
];

//  Appearance Panel
export const APPEARANCE_LIST_GRID_VIEW = 0;
export const APPEARANCE_LIST_SMALL_IMAGES = 1;
export const APPEARANCE_LIST_LARGE_IMAGES = 2;
export const APPEARANCE_LIST_OPTIONS = [
  { label: "2 Column Grid", value: APPEARANCE_LIST_GRID_VIEW },
  { label: "Large Images", value: APPEARANCE_LIST_LARGE_IMAGES },
  { label: "Small Images with Info", value: APPEARANCE_LIST_SMALL_IMAGES },
];

export const APPEARANCE_PDP_OPTION_1 = "APPEARANCE_PDP_OPTION_1";
export const APPEARANCE_PDP_OPTION_2 = "APPEARANCE_PDP_OPTION_2";
export const APPEARANCE_PDP_OPTION_3 = "APPEARANCE_PDP_OPTION_3";
export const APPEARANCE_PDP_OPTIONS = [
  { label: "PDP Option 1", value: APPEARANCE_PDP_OPTION_1 },
  { label: "PDP Option 2", value: APPEARANCE_PDP_OPTION_2 },
  { label: "PDP Option 3", value: APPEARANCE_PDP_OPTION_3 },
];

// Images for PLPs
export const IMAGE_PLP_GRID_VIEW =
  "https://cdn.shopify.com/s/files/1/0399/6362/3573/files/Grid_View_PLP.png?v=1619490127";
export const IMAGE_PLP_SMALL_IMAGES =
  "https://cdn.shopify.com/s/files/1/0399/6362/3573/files/Small_Image_PLP.png?v=1619490127";
export const IMAGE_PLP_LARGE_IMAGES =
  "https://cdn.shopify.com/s/files/1/0399/6362/3573/files/Large_Image_PLP.png?v=1619490127";

// Saved JSON Objects
export const STORAGE_NAME_HOME_PAGE_OBJECT = "home page storage";
export const STORAGE_NAME_SHOP_SECTION_OBJECT = "shop section storage";
export const STORAGE_NAME_CUSTOM_BANNER_OBJECT = "custom banner storage";
export const STORAGE_NAME_APPEARANCE_OBJECT = "appearance variables storage";

// PLP Options / Types
export const PLP_IMAGES_SMALL = "PLP_IMAGES_SMALL";
export const PLP_LIST_GRIDVIEW = "PLP_LIST_GRIDVIEW";
export const PLP_IMAGES_LARGE = "PLP_IMAGES_LARGE";

// PDP Images Options
export const PDP_IMAGES_CAROUSEL = "PDP_IMAGES_CAROUSEL";
export const PDP_IMAGES_THUMBNAILS = "PDP_IMAGES_THUMBNAILS";

// PDP Variants Options
export const PDP_VARIANTS_DROPDOWN = "PDP_VARIANTS_DROPDOWN";
export const PDP_VARIANTS_THUMBNAILS = "PDP_VARIANTS_THUMBNAILS";

// consortia
export const CAT_SMALL_BUSINESS = "CAT_SMALL_BUSINESS";
export const CAT_BLACK = "CAT_BLACK";
export const CAT_WOMEN = "CAT_WOMAN";
export const CAT_LGBTQ = "CAT_LGBTQ";
export const CAT_SUSTAINABLE = "CAT_SUSTAINABLE";

// installed state
export const INSTALLED = "INSTALLED";
export const PREVIEW = "PREVIEW";
export const HIDDEN = "HIDDEN";

// tab indicator color
export const INDICATOR_COLOR = '#d9f4fc';
