import * as React from "react";

import {
  Card,
} from '@mui/material';


import DestinationRowItem from "./DestinationRowItem";


class DestinationsResourceList extends React.Component {
  state = {
    destinations: this.props.destinations,
  };


  //
  upButtonPressed = (e) => {
    this.props.moveDestinationUp(e);
  };

  //
  downButtonPressed = (e) => {
    this.props.moveDestinationDown(e);
  };

  //
  removeDestination = (e) => {
    this.props.removeDestination(e);
  };


  //  propogate changed item to parent
  changeImageForItem = (item) => {
    this.props.changeImageForItem(item);
  };


  render() {
    return (
      <Card>
        {this.state.destinations.map((item) => (
          <div key={item.destinationId}>
            <DestinationRowItem
              item={item}
              changeImageForItem={this.changeImageForItem}
              downButtonPressed={this.downButtonPressed}
              upButtonPressed={this.upButtonPressed}
              removeDestination={this.removeDestination}
            />
          </div>
        ))}
      </Card>
    );
  }

}

export default DestinationsResourceList;
