import * as React from "react";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

import * as bbConstants from "./constants/bb-constants";

class AppStatusCard extends React.Component {
  state = {
    shopValue: this.props.shopValue,
    installedState: this.props.installedState,
    previewCode: this.props.previewCode,
    waitingForNetworkCall: this.props.waitingForNetworkCall,
  };


  //
  saveButtonPressed = () => {
    var installedState = this.state.installedState;
    this.props.setInstalledState(installedState);
  };


  //
  handleChange = (event) => {
    var installedState = event.target.value
    this.setState({ installedState })
  };


  //
  render() {
    // var primaryFooterAction = null;
    var installedState = this.state.installedState;

    var hiddenHelpText = "";
    var previewHelpText = "";
    var installedHelpText = "";
    var helpText = "";
    if (installedState === bbConstants.HIDDEN) {
      hiddenHelpText = "Your Store will not be included in the mobile app";
      helpText = hiddenHelpText;
    } else if (installedState === bbConstants.PREVIEW) {
      previewHelpText = "In the app, search for " + this.state.previewCode;
      helpText = previewHelpText;
    } else if (installedState === bbConstants.INSTALLED) {
      installedHelpText =
        "Your Store is open for business in the Small Business Mall app!";
      helpText = installedHelpText;
    }


    return (
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: '0px',
          padding: '5px'
        }}>
        <FormControl
          style={{ margin: '15px' }}
        >
          <label
            style={{ 
              display: 'flex',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}>
            Store status in the Mobile App:
          </label>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={installedState}
            onChange={this.handleChange}
          >
            <FormControlLabel
              value={bbConstants.HIDDEN}
              control={<Radio />}
              label="Hidden"
              style={{ margin: '-5px 0' }}
            />
            <FormControlLabel
              value={bbConstants.PREVIEW}
              control={<Radio />}
              label="Preview"
              style={{ margin: '-5px 0' }}
            />
            <FormControlLabel
              value={bbConstants.INSTALLED}
              control={<Radio />}
              label="Installed"
              style={{ margin: '-5px 0' }}
            />
          </RadioGroup>
          <br></br>
          <label>{helpText}</label>
        </FormControl>
        <Button
          size="small"
          variant="contained"
          style={{ marginLeft: '15px', marginBottom: '5px' }}
          onClick={this.saveButtonPressed}
          disabled={this.state.waitingForNetworkCall}
        >
          Update Store Status
        </Button>

      </Card>
    );
  }
}

export default AppStatusCard;
