import * as React from "react";

import {
  Button,
  Card,
} from '@mui/material';

import * as bbConstants from "./constants/bb-constants";


class BannerScreensResourceList extends React.Component {
  state = {
    availableBannerScreens: this.props.availableBannerScreens,
    selectedBannerScreen: this.props.selectedBannerScreen,
    waitingForNetworkCall: this.props.waitingForNetworkCall,
  };


  //
  newBannerScreenPressed = (e) => {
    var newBannerScreen = {
      BannerScreenName: "",
      bannerSize: bbConstants.BANNER_MEDIUM,
      autoRotateMilliSecondsPause: 0,
      extraData: "",
      destinations: [],
    };

    this.editButtonPressed(newBannerScreen);
  };

  //
  editButtonPressed = (e) => {
    var selectedBannerScreen = e;
    this.setState({
      selectedBannerScreen,
    });
    this.props.setSelectedBannerScreen(selectedBannerScreen);
  };

  //
  deleteButtonPressed = (e) => {
    var bannerScreen = e;
    var bannerScreenName = bannerScreen.title;
    let isExecuted = window.confirm("Really delete '" + bannerScreenName + "'?");

    // just kidding
    if (!isExecuted) {
      return;
    }

    this.setState( { waitingForNetworkCall: true });
    this.props.deleteBannerScreen(bannerScreen);

    // TODO - ** Jan 2024 **  do the Delete stuff
    // this.setState({ displayMode: display_mode_editBannerScreens, selectedBannerScreen })
  };

  //
  render() {

    return (
      <Card key={this.state.availableBannerScreens}>
        <Card 
          variant="outlined"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '15px',
            padding: '5px'
          }}
        >

          <label
            style={{
              paddingLeft: '20px',
              paddingTop: '10px',
              marginRight: 'auto',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}>
            Banner Screens
          </label>

          <label
            style={{
              paddingLeft: '20px',
              paddingTop: '10px',
              marginRight: 'auto',
              marginBottom: '15px',
              fontSize: '14px',
            }}>
            &nbsp;&nbsp;&nbsp;&nbsp;Note: A Screen may not be deleted if it is currently used
          </label>

          <br></br>

          {this.state.availableBannerScreens.map((item) => (
            <div
              key={item.title}
              style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginBottom: '15px' }}>

              <Button
                size="small"
                variant="contained"
                diasbled={this.state.waitingForNetworkCall}
                onClick={() => {
                  this.editButtonPressed(item);
                }}>
                Edit
              </Button>

              <Button
                size="small"
                variant="contained"
                // disabled={item.carouselInUse}
                diasbled={this.state.waitingForNetworkCall}
                onClick={() => {
                  this.deleteButtonPressed(item);
                }}
                style={{ marginLeft: '20px' }}>
                Delete
              </Button>

              <label
                style={{
                  paddingLeft: '20px',
                  fontWeight: 'bold',
                }}
              >
                {item.title}
              </label>

            </div>
          ))}

          <Button
            size="small"
            variant="contained"
            onClick={this.newBannerScreenPressed}
            style={{ marginLeft: '400px', marginTop: '20px', marginBottom: '15px' }}
          >
            New Screen
          </Button>

        </Card>
      </Card>

      // <Card
      // primaryFooterAction={{
      //   content: "New Screen",
      //   onAction: () => this.newBannerScreenPressed(),
      // }}
      // </Card>
    );
  }
}

export default BannerScreensResourceList;
