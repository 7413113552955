import * as React from "react";

import { Card, FormGroup } from '@mui/material';

import * as bbConstants from "./constants/bb-constants";

import {
  plpSmallImages_imageUrl,
  plpListGridview_imageUrl,
  plpLargeImages_imageUrl,
} from "./constants/endpointDefinition";

class PLPAppearanceCard extends React.Component {
  state = {
    shopValue: this.props.shopValue,
    listType: this.props.shopValue.listType,
  };

  //
  handlePlpTypeStateChange = (e) => {
    var listType = e.target.value;
    
    if (listType === "PLP_IMAGES_SMALL") {
      document.getElementById("image").src = plpSmallImages_imageUrl;
    } else if (listType === "PLP_LIST_GRIDVIEW") {
      document.getElementById("image").src = plpListGridview_imageUrl;
    } else if (listType === "PLP_IMAGES_LARGE") {
      document.getElementById("image").src = plpLargeImages_imageUrl;
    }

    this.setState({ listType });
    this.props.updateListType(listType);
  };

  //
  render() {
    var listType = this.state.listType;

    var plpImageUrl = plpSmallImages_imageUrl;
    if (listType === bbConstants.PLP_IMAGES_SMALL) {
      plpImageUrl = plpSmallImages_imageUrl;
    } else if (listType === bbConstants.PLP_LIST_GRIDVIEW) {
      plpImageUrl = plpListGridview_imageUrl;
    } else if (listType === bbConstants.PLP_IMAGES_LARGE) {
      plpImageUrl = plpLargeImages_imageUrl;
    }

    return (
      <Card
        variant="outlined"
        style={{
          margin: '0px',
          padding: '0px'
        }}>
        <FormGroup
          id="plpRadios"
          selection={listType}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '15px',
            padding: '5px'
          }}>

          <label
            style={{
              margin: '0px',
              marginBottom: '10px',
              display: 'flex',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}>
            Product List Page Appearance:
          </label>
          <div padding='5px'>
            <input
              type="radio"
              id="PLP_IMAGES_SMALL"
              name="plp_types"
              onChange={this.handlePlpTypeStateChange}
              value={bbConstants.PLP_IMAGES_SMALL}
              checked={listType === bbConstants.PLP_IMAGES_SMALL}
            />
            <label style={{ marginLeft: '10px' }}>Small Product Images</label>
          </div>

          <div padding='5px'>
            <input
              type="radio"
              id="PLP_LIST_GRIDVIEW"
              name="plp_types"
              onChange={this.handlePlpTypeStateChange}
              value={bbConstants.PLP_LIST_GRIDVIEW}
              checked={listType === bbConstants.PLP_LIST_GRIDVIEW}
            />
            <label style={{ marginLeft: '10px' }}>Two Column Grid</label>
          </div>

          <div padding='5px'>
            <input
              type="radio"
              id="PLP_IMAGES_LARGE"
              name="plp_types"
              onChange={this.handlePlpTypeStateChange}
              value={bbConstants.PLP_IMAGES_LARGE}
              checked={listType === bbConstants.PLP_IMAGES_LARGE}
            />
            <label style={{ marginLeft: '10px' }}>Large Product Images</label>
          </div>

          <br></br>
          <img src={plpImageUrl} id="image" width={150} />

        </FormGroup>
      </Card>
    );
  }
}

export default PLPAppearanceCard;
