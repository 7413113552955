import * as React from "react";

import {
  Button,
  Card,
  CardActions
} from '@mui/material';

import * as bbConstants from "./constants/bb-constants";

import { BASE_URL } from "./constants/endpointDefinition";
import ConfigVariables from "./ConfigVariables";
import PLPAppearanceCard from "./PLPAppearanceCard";
import PDPOptionsCard from "./PDPOptionsCard";

class ShopAppearance extends React.Component {
  state = {
    shopID: this.props.shopID,
    shopValue: this.props.shopValue,

    colorValue: '#ffffff',
  };

  /*
    State variables are updated, but the original Shop is not being changed
    push changed Shop to parent
  */
  persistShop() {
    var shop = this.state.shopValue;
    this.props.setShop(shop);
  }


  //
  updateConfigVariables = (e) => {
    var configVariables = e;
    var shopValue = this.state.shopValue;
    shopValue.shopifyConfigVariables = configVariables;

    this.setState({ shopValue });
    this.persistShop(shopValue);
  };

  //
  updatePdpProductImagesStyle = (e) => {
    var pdpProductImagesStyle = e;
    var shopValue = this.state.shopValue;
    shopValue.pdpProductImagesStyle = pdpProductImagesStyle;
    this.setState({ shopValue });
    this.persistShop(shopValue);
  };

  //
  updatePdpVariantsStyle = (e) => {
    var pdpVariantsStyle = e;
    var shopValue = this.state.shopValue;
    shopValue.pdpVariantsStyle = pdpVariantsStyle;
    this.setState({ shopValue });
    this.persistShop(shopValue);
  };

  //
  updateListType = (e) => {
    var listType = e;
    var shopValue = this.state.shopValue;
    shopValue.listType = listType;

    this.setState({ shopValue });
    this.persistShop(shopValue);
  };


  //
  consortiaSelected = (consortiaState) => {
    var consortia = [];

    var smallBusinessChecked = consortiaState.smallBusinessChecked;
    var blackOwnedChecked = consortiaState.blackOwnedChecked;
    var womenOwnedChecked = consortiaState.womenOwnedChecked;
    var lgbtqOwnedChecked = consortiaState.lgbtqOwnedChecked;
    var sustainableOwnedChecked = consortiaState.sustainableOwnedChecked;

    if (smallBusinessChecked) {
      consortia.push(bbConstants.CAT_SMALL_BUSINESS);
    }
    if (blackOwnedChecked) {
      consortia.push(bbConstants.CAT_BLACK);
    }
    if (womenOwnedChecked) {
      consortia.push(bbConstants.CAT_WOMEN);
    }
    if (lgbtqOwnedChecked) {
      consortia.push(bbConstants.CAT_LGBTQ);
    }
    if (sustainableOwnedChecked) {
      consortia.push(bbConstants.CAT_SUSTAINABLE);
    }

    var shopValue = this.state.shopValue;
    shopValue["consortia"] = consortia;
    this.setState({ shopValue });
  };

  //
  shopCategoriesSelected = (shopCategories) => {
    var shopValue = this.state.shopValue;
    shopValue["categories"] = shopCategories;
    this.setState({ shopValue });
  };

  // Lock buttons
  setStoreNameButtonPressed = () => {
    var disableStoreNameCard = false;
    this.setState({ disableStoreNameCard });
  };
  setAPIKeyButtonPressed = () => {
    var disableApiKeyCard = false;
    this.setState({ disableApiKeyCard });
  };
  setContactNameButtonPressed = () => {
    var disableContactNameCard = false;
    this.setState({ disableContactNameCard });
  };
  setContactPhoneButtonPressed = () => {
    var disableContactPhoneCard = false;
    this.setState({ disableContactPhoneCard });
  };
  setContactEmailButtonPressed = () => {
    var disableContactEmailCard = false;
    this.setState({ disableContactEmailCard });
  };


  // Color Picker
  handleColorPickerChange = (newColorValue) => {
    this.setState({ colorValue: newColorValue })
  }

  //
  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'left'
        }}>
        <Card style={{ padding: '30px' }}>

          <ConfigVariables
            shopifyConfigVariables={
              this.state.shopValue.shopifyConfigVariables
            }
            updateConfigVariables={this.updateConfigVariables}
          />

          <br></br>

          <PDPOptionsCard
            shopValue={this.state.shopValue}
            updateListType={this.updateListType}
            updatePdpProductImagesStyle={this.updatePdpProductImagesStyle}
            updatePdpVariantsStyle={this.updatePdpVariantsStyle}
          />

          <br></br>

          <PLPAppearanceCard
            shopValue={this.state.shopValue}
            updateListType={this.updateListType}
          />

          <br></br>

          <CardActions style={{ marginLeft: '50px' }}>
            <Button
              size="small"
              variant="contained"
              style={{ margin: '15px' }}
              onClick={this.sendUpdatedShop}
              disabled={this.state.waitingForNetworkCall}
            >
              Submit Changes
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }

  //
  sendUpdatedShop = (e) => {
    if (null != e) {
      e.preventDefault();
    }

    let shop = this.state.shopValue;

    var payload = {
      mallID: 2,
      shop,
    };

    var scope = this;
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/add-addOrUpdateShop";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var shopValue = JSON.parse(this.responseText);

        // re-lock the locked fields
        var disableApiKeyCard = true;
        var disableStoreNameCard = true;
        scope.setState({ 
          disableApiKeyCard, 
          disableStoreNameCard, 
          shopValue,
          waitingForNetworkCall: false,
         });
        scope.props.setShop(shopValue);
        alert("Success!  Changes submitted!");

      } else {
        // TODO - Error message to User
        console.log("AES sendUpdatedShop not 200  status: " + xhr.status);
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);
    
    // Sending data with the request
    this.setState( { waitingForNetworkCall: true })
    xhr.send(data);
  };

  //
  sendInstalledState(installedState) {
    let shopID = this.state.shopValue.shopId;
    
    var payload = {
      shopID,
      installedState,
    };

    // var scope = this;
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/setInstalledState";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        alert("Success!  Changes submitted!");
      } else {
        // TODO - Error message to User
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);
    
    // Sending data with the request
    xhr.send(data);
  }
}

export default ShopAppearance;
