import React from 'react';

import { Button } from '@mui/material';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      password: '',
      incorrectPassword: false,
      waitingForNetworkCall: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  //
  handleChange(event) {
    this.setState({ value: event.target.value });
  }


  //
  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  //
  handleResetPasswordClick = (event) => {
    this.props.requirePasswordReset();
  }


  //
  handleSubmit(event) {
    this.fetchShopForUserName();
    event.preventDefault();
  }


  //
  fetchShopForUserName = () => {
    var userName = this.state.value
    var password = this.state.password

    const CryptoJS = require('crypto-js');
    const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

    var payload = {
      UserName: userName,
      Hashword: hashedPassword
    };

    var scope = this;
    let xhr = new XMLHttpRequest();
    const BASE_URL = "https://qob7bezeh8.execute-api.us-east-1.amazonaws.com/dev";
    let url = BASE_URL + "/shopifyLogin";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var data = JSON.parse(this.responseText);
        scope.handleShopDataDownloaded(data);
      } else {
        // TODO - Error message to User
        console.log("fetchShopForUserName not 200  status: " + xhr.status);
      }
    };

    var data = JSON.stringify(payload);
    // const waitingForNetworkCall = true;
    this.setState({ waitingForNetworkCall: true });  // starting network call
    xhr.send(data);
  };


  // called when the Shop Data has been downloaded from the API
  handleShopDataDownloaded = (e) => {
    if (null == e)
      return;

    // console.log("LoginForm handleShopDataDownloaded e: ", e)

    var requirePasswordReset = e["RequirePasswordReset"];
    var incorrectPassword = e["IncorrectPassword"];

    if (requirePasswordReset != null) {
      this.props.requirePasswordReset()
      this.setState({ waitingForNetworkCall: false });  // finished network call

    } else if (incorrectPassword != null) {
      this.setState({
        isShopLoaded: false,
        incorrectPassword: true,
        waitingForNetworkCall: false,
      });

    } else {
      // Store the Shop
      const shop = e["shop"];
      // console.log("LoginForm shop: ", shop)

      const availableCarousels = e["availableCarousels"];
      // console.log("LoginForm availableCarousels: ", availableCarousels)

      const bannerScreens = e["bannerScreens"];
      // console.log("LoginForm bannerScreens: ", bannerScreens)

      const categories = e["categories"];

      const shopID = shop.shopId;

      this.setState({
        shopValue: shop,
        shopID: shopID,
        availableCarousels: availableCarousels,
        bannerScreens: bannerScreens,
        categories: categories,
        isShopLoaded: true,
        waitingForNetworkCall: false,
      });

      this.props.setShopData(shop, availableCarousels, bannerScreens, categories)

    }
  };


  //
  render() {

    const labelStyle = {
      margin: '10px',
      width: '120px',
      textAlign: 'right',
    }
    const divStyle = {
      justifyContent: 'left',
      width: '500px',
      display: 'flex',
      alignItems: 'flex-center',
    }
    const inputStyle = {
      margin: '10px',
      width: '200px'
    }


    return (

      <div>

        <div style={divStyle}>
          <label style={labelStyle}>Username:</label>
          <input
            style={{ margin: '10px' }}
            size="30"
            value={this.state.value}
            onChange={this.handleChange}
          />
        </div>

        <div style={divStyle}>
          <label style={labelStyle}>Password:</label>
          <input
            style={{ margin: '10px' }}
            size="30"
            type='password'
            value={this.state.password}
            onChange={this.handleChangePassword}
          />
        </div>

        {this.state.incorrectPassword && (
          <div style={divStyle}>
            <label
              style={{
                justifyContent: 'center',
                width: '450px',
                display: 'flex',
                marginTop: '10px',
                color: 'red',
                alignItems: 'center',
              }}
            >
              Incorrect password or User ID
            </label>
          </div>
        )}



        <br></br>
        <Button
          size="small"
          variant="contained"
          style={{ margin: '15px' }}
          onClick={this.handleResetPasswordClick}
        >
          Reset Password
        </Button>

        <Button
          size="small"
          variant="contained"
          style={{ margin: '15px' }}
          onClick={this.handleSubmit}
          disabled={this.state.waitingForNetworkCall}
        >
          Login
        </Button>

      </div>
    );
  }
}

export default LoginForm
