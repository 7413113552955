import * as React from "react";

import { Card, TextField } from '@mui/material';

class DestinationURLCard extends React.Component {
  state = {
    destinationURL: this.props.destinationURL,
  };

  //
  render() {
    return (
      <div>
        <label>URL:</label>
        <input
          value={this.state.destinationURL}
          onChange={this.handleKeyentrySearchTermStateChange}
          onKeyPress={this.handleKeypress}
        />
      </div>
    );
  }

  // Save Collection
  handleKeyentrySearchTermStateChange = (e) => {
    var destinationURL = e.target.value;
    this.setState({ destinationURL: destinationURL });
    this.props.handleURLSelection(destinationURL);
  };
}

export default DestinationURLCard;
