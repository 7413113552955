import * as React from "react";
import axios from 'axios';

import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';


class DestinationProductCard extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      productIdReaable: this.props.productIdReadable,
      productId: this.props.extraData,
      availableProducts: [],
      apiKey: this.props.apiKey,
      shopDomain: this.props.shopDomain,
      showFormControl: false,
    };

    this.fetchProducts();
  }

  //
  toggleFormControlVisibility = () => {
    this.setState((prevState) => ({
      showFormControl: !prevState.showFormControl,
    }));
  };


  //
  render() {

    const divStyle = {
      width: '400px',
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: '0px',
      marginTop: '5px',
      padding: '5px',
    }

    const formControlStyle = {
      transition: `opacity 1s ease ${this.state.showFormControl ? '1.0s' : '1.0s'}`, // Adjust durations accordingly
      opacity: this.state.showFormControl ? 1 : 0, // Set initial opacity based on showFormControl state
    };


    // Update your render method
    return (
      <div
        style={{
          border: '1px solid #000',
          padding: '10px',
        }}
      >
        <div style={divStyle}>
          <label style={{ margin: '7px' }}>Selected: </label>
          <label style={{ margin: '7px', marginLeft: '15px' }}>
            {this.state.productIdReaable}
          </label>

          <Button
            onClick={this.toggleFormControlVisibility}
            style={{ marginBottom: '-15px', marginLeft: '40px', paddingBottom: '10px' }}
          >
            {this.state.showFormControl ? 'Hide Products' : 'Show Products'}
          </Button>
        </div>

        <div style={formControlStyle}>
          {this.state.showFormControl && (
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.collectionId}
                onChange={this.handleProductSelection}
              >
                {this.state.availableProducts.map((product) => (
                  <FormControlLabel
                    key={product.node.id}
                    value={product.node.id}
                    control={<Radio size="small" />}
                    label={product.node.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </div>
      </div>
    );
  }


  // Save Products
  handleProductSelection = (e, newProductId) => {
    // const products = resources.selection.map((product) => product);
    const products = this.state.availableProducts
    var productIdReaable, productId;

    // get relevant data from the product
    if (products) {
      for (const product of products) {
        if (newProductId == product.node.id) {
          productIdReaable = product.node.title;
          productId = newProductId;
          break;
        }
      }
    }

    // tell parent a selection was made to enable the Save button as needed
    this.props.handleValueEntered();

    // persist to parent/caller
    this.props.onSelection(productIdReaable, productId);

    //
    this.setState({ productId, productIdReaable });
  };


  fetchProducts = async () => {
    // Check if products are already loaded
    const availableProducts = this.state.availableProducts;
    if (availableProducts && availableProducts.length > 0) {
      return availableProducts;
    }

    try {
      const storefrontAccessToken = this.state.apiKey;
      const shopifyStoreUrl = 'https://' + this.state.shopDomain;

      // const response = await axios.post(
      //   `${shopifyStoreUrl}/api/2021-10/graphql.json`,
      //   {
      //     query: `
      //       {
      //         products(first: 100) {
      //           edges {
      //             node {
      //               id
      //               title
      //               description
      //               variants(first: 1) {
      //                 edges {
      //                   node {
      //                     quantityAvailable: inventoryQuantity
      //                     availableForSale
      //                   }
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }
      //     `,
      //   },
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
      //     },
      //   }
      // );
      

      const response = await axios.post(
        `${shopifyStoreUrl}/api/2021-10/graphql.json`,
        {
          query: `
            {
              products(first: 100) {
                edges {
                  node {
                    id
                    title
                    description
                    variants(first: 10) {
                      edges {
                        node {
                          quantityAvailable
                          availableForSale
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
          },
        }
      );

      const products = response.data.data.products.edges;
      this.setState({
        availableProducts: products,
        loadingProducts: false,
      });

      // console.log("Products: ", products);

      return products;
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

}

export default DestinationProductCard;
