import * as React from "react";


import {
  Card,
  Button,
} from '@mui/material';

import * as bbConstants from "./constants/bb-constants";

class CarouselsResourceList extends React.Component {
  state = {
    availableCarousels: this.props.availableCarousels,
    selectedCarousel: this.props.selectedCarousel,
  };


  //
  newCarouselPressed = (e) => {
    var newCarousel = {
      carouselName: "",
      bannerSize: bbConstants.BANNER_MEDIUM,
      autoRotateMilliSecondsPause: 0,
      extraData: "",
      destinations: [],
    };
    this.editButtonPressed(newCarousel);
  };


  //
  editButtonPressed = (e) => {
    var selectedCarousel = e;
    this.setState({
      selectedCarousel,
    });
    this.props.setSelectedCarousel(selectedCarousel);
  };


  //
  deleteButtonPressed = (e) => {
    var carousel = e;

    var carouselName = "";
    try {
      carouselName = carousel.carouselName;
    } catch (error) {
      // TODO - Error message to User
      // console.error('CRL deleteButtonPressed - Error accessing carousel.carouselName:', error);
    }
    
    let isExecuted = window.confirm("Really delete '" + carouselName + "'?");

    // just kidding
    if (!isExecuted) {
      return;
    }

    this.props.deleteCarousel(carousel);
  };


  //
  render() {
    return (
      <Card>
        <Card variant="outlined"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '15px',
            padding: '5px'
          }}
        >

          <label
            style={{
              paddingLeft: '20px',
              paddingTop: '10px',
              marginRight: 'auto',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}>
            Add or Delete Carousels
          </label>

          <label
            style={{
              paddingLeft: '20px',
              paddingTop: '10px',
              marginRight: 'auto',
              marginBottom: '15px',
              fontSize: '14px',
            }}>
            &nbsp;&nbsp;&nbsp;&nbsp;Note: A Carousel may not be deleted if it is currently used on a Screen
          </label>

          <br></br>

          {this.state.availableCarousels.map((item) => (

            <div 
              key={item.carouselName}
              style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginBottom: '15px' }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  this.editButtonPressed(item);
                }}>
                Edit
              </Button>

              <Button
                size="small"
                variant="contained"
                disabled={item.carouselInUse}
                onClick={() => {
                  this.deleteButtonPressed(item);
                }}
                style={{ marginLeft: '20px' }}>
                Delete
              </Button>

              <label
                style={{
                  paddingLeft: '20px',
                  fontWeight: 'bold',
                }}
              >
                {item.carouselName}
              </label>

            </div>
          ))}

          <Button
            size="small"
            variant="contained"
            onClick={this.newCarouselPressed}
            style={{ marginLeft: '400px', marginTop: '20px', marginBottom: '15px' }}
          >
            New Carousel
          </Button>

        </Card>
      </Card>
    );
  }
}

export default CarouselsResourceList;
