import * as React from "react";
import axios from 'axios';

import {
  Button,
  Grid,
} from '@mui/material';

import { BASE_URL } from "./constants/endpointDefinition";
import { INDICATOR_COLOR } from "./constants/bb-constants";
import * as bbConstants from "./constants/bb-constants";

import BannerScreensResourceList from "./BannerScreensResourceList";

export const display_mode_available = "display_mode-available";
export const display_mode_edit = "display_mode-edit";

class BannerScreenBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shopID: this.props.shopID,
      shopValue: this.props.shopValue,
      displayMode: display_mode_available,

      collectionsSet: false,
      nameSet: false,

      bannerScreenName: this.props.bannerScreenName,
      bannerScreenId: this.props.bannerScreenId,
      featureType: this.props.bannerScreenType,
      selectedCarousels: [],
      availableCarousels: this.props.availableCarousels,
      availableBannerScreens: this.props.bannerScreens,
      rpOpen: false,

      availableCollections: [],
      destinationPanelState: 'collections',

      waitingForNetworkCall: false,
    };
    this.fetchCollections();
  };


  //
  setSelectedBannerScreen = (bannerScreen) => {
    var selectedBannerScreen = bannerScreen;
    
    var bannerScreenName;
    var bannerScreenId;
    var selectedCarousels;

    if (null != bannerScreen.item) {
      bannerScreenName = selectedBannerScreen.item.title;
      selectedCarousels = selectedBannerScreen.item.carousels;
      bannerScreenId = selectedBannerScreen.item.bannerScreenId;
    } else {
      bannerScreenName = selectedBannerScreen.title;
      bannerScreenId = selectedBannerScreen.bannerScreenId;
      selectedCarousels = selectedBannerScreen.carousels;
    }

    var featureType = bbConstants.BANNER_SCREEN_ALL_COLLECTIONS_ALWAYS;
    var collectionsSet = false;
    if (selectedCarousels == null) {
      selectedCarousels = [];
    }
    if (selectedCarousels.length > 0) {
      featureType = bbConstants.BANNER_SCREEN_CUSTOMIZE;
      collectionsSet = true;
    }

    this.setState({
      selectedBannerScreen,
      bannerScreenName,
      bannerScreenId,
      selectedCarousels,
      featureType,
      collectionsSet,
      displayMode: display_mode_edit,
    });
  };

  //
  handleChangeBannerScreenName = (e) => {
    var bannerScreenName = e.target.value;
    var nameSet = null != bannerScreenName && bannerScreenName.length > 0;
    this.setState({ nameSet: nameSet, bannerScreenName });
  };

  //  Destination Type Radio Buttons
  handleDestinationTypeStateChange = (e) => {
    var featureType = e.target.value;

    // User needs to set a value for most destinations
    // Disable the Button when changing Cards - reEnable with a callback when the user sets the vlaue
    var collectionsSet = true;

    switch (featureType) {
      case bbConstants.BANNER_SCREEN_ALL_COLLECTIONS_ALWAYS:
        collectionsSet = true;
        break;

      case bbConstants.BANNER_SCREEN_SELECT_COLLECTIONS:
        var moreThanZero = this.state.selectedCarousels.length > 0;
        collectionsSet = moreThanZero;
        break;

      case bbConstants.BANNER_SCREEN_CUSTOMIZE:
        collectionsSet = false;
        break;

      default:
        collectionsSet = true;
    }

    this.setState({ featureType, collectionsSet });
  };

  //
  addButtonPressed = (e) => {
    var selectedCarousel = e;
    var selectedCarousels = this.state.selectedCarousels;
    selectedCarousels.push(selectedCarousel);

    this.setState({
      selectedCarousels,
      collectionsSet: true,
    });
  };

  //
  removeButtonPressed = (e) => {
    var selectedCarousel = e;
    var selectedCarousels = this.state.selectedCarousels;
    var index = selectedCarousels.indexOf(selectedCarousel);
    selectedCarousels.splice(index, 1);

    var collectionsSet = selectedCarousels.length > 0;

    this.setState({
      selectedCarousels,
      collectionsSet,
    });
  };

  //
  moveCarouselUp = (e) => {
    var selectedCarousels = this.state.selectedCarousels;
    var item = e;
    var index = selectedCarousels.indexOf(item);

    if (0 === index) {
      // ignore it - can't move any higher
    } else {
      var previous = selectedCarousels[index - 1];
      selectedCarousels[index - 1] = item;
      selectedCarousels[index] = previous;

      this.setState({ selectedCarousels });
    }
  };

  //
  moveCarouselDown = (e) => {
    var selectedCarousels = this.state.selectedCarousels;
    var item = e;
    var index = selectedCarousels.indexOf(item);
    var length = selectedCarousels.length;

    if (length === index + 1) {
      // ignore it - can't move any lower
    } else {
      var next = selectedCarousels[index + 1];
      selectedCarousels[index + 1] = item;
      selectedCarousels[index] = next;
      this.setState({ selectedCarousels });
    }
  };


  //  Copied from Heroku - 1/17/2024
  newBannerScreenPressed = (e) => {
    var newBannerScreen = {
      BannerScreenName: "",
      bannerSize: bbConstants.BANNER_MEDIUM,
      autoRotateMilliSecondsPause: 0,
      extraData: "",
      destinations: [],
    };

    this.editButtonPressed(newBannerScreen);
  };


  //
  editButtonPressed = (e) => {
    var selectedBannerScreen = e;
    this.setState({
      selectedBannerScreen,
    });
    this.setSelectedBannerScreen(selectedBannerScreen);
  };


  //
  addCollectionDestination = (e) => {
    const newCollectionId = e.id;
    const availableCollections = this.state.availableCollections;
    var newCollection = null;
    for (const collection of availableCollections) {
      if (collection.id === newCollectionId) {
        newCollection = collection;
        break;
      }
    }
    var base64id = btoa(newCollectionId);

    // see if there's an image
    var newImage = "";
    if (newCollection.image != null) {
      newImage = newCollection.image.originalSrc;
    }

    var newDestination = {
      extraData: base64id,
      featureType: bbConstants.DESTINATION_COLLECTION,
      image: newImage,
      title: newCollection.title
    }

    var newCarousel = {
      "autoRotateMilliSecondsPause": 0,
      "bannerSize": bbConstants.BANNER_MEDIUM,
      "carouselName": newCollection.title,
      "extraData": "",
      "destinations": [newDestination]
    };

    newCarousel.destinations.push(newDestination)
    var selectedCarousels = this.state.selectedCarousels
    selectedCarousels.push(newCarousel)

    this.setState({ selectedCarousels })
    // Does this need to be pushed up?
  }


  // Destinations
  toggleDestinationVisibility = (e) => {
    var destinationPanelState = e.target.id
    if (this.state.destinationPanelState === destinationPanelState) {
      destinationPanelState = 'none'
    }
    this.setState({ destinationPanelState })
  };



  //
  render() {
    // display mode
    if (this.state.displayMode === display_mode_available) {
      return (
        <div key={this.state.availableBannerScreens}>
          {this.state.availableBannerScreens ? (
            <BannerScreensResourceList key={this.state.waitingForNetworkCall}
              availableBannerScreens={this.state.availableBannerScreens}
              setSelectedBannerScreen={this.setSelectedBannerScreen}
              deleteBannerScreen={this.deleteBannerScreen}
              showAvailableBannerScreens={this.showAvailableBannerScreens}
              waitingForNetworkCall={this.state.waitingForNetworkCall}
            />
          ) : (
            <p>No available banner screens.</p>
          )}
        </div>
      );
    } else if (this.state.displayMode === display_mode_edit) {
      // display mode

      // disable the Save button if either the Name is NOT set, OR the Collections
      // have not been set (both need to be set to enable the Save button)
      // var saveButtonIsDisabled =
      //   !this.state.nameSet || !this.state.collectionsSet;


      // start with Collections
      var newDestinationsCard = (
        <div>
          {this.state.availableCollections.map((Collection) => (
            <div
              style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginBottom: '15px' }}>

              <Button
                size="small"
                variant="contained"
                value={Collection.id}
                onClick={() => {
                  this.addCollectionDestination(Collection)
                }}>
                Add
              </Button>

              <label
                style={{
                  paddingLeft: '20px',
                }}
              >
                {Collection.title}
              </label>

            </div>
          ))}
        </div>
      );
      if (this.state.destinationPanelState === 'carousels') {
        newDestinationsCard = (
          <div>
            {this.state.availableCarousels.map((item) => (
              <div
                style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginBottom: '15px' }}>

                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    this.addButtonPressed(item);
                  }}>
                  Add
                </Button>

                <label
                  style={{
                    paddingLeft: '20px',
                  }}
                >
                  {item.carouselName}
                </label>

              </div>
            ))}
          </div>
        )
      }

      return (

        <div key={this.state.availableBannerScreens}>

          {/* Buttons */}
          <div
            style={{
              marginLeft: '20px',
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              width: '550px'
            }}>

            <Button
              size="small"
              variant="contained"
              style={{ marginLeft: '7px' }}
              onClick={() => {
                this.showAvailableBannerScreens();
              }}
            >
              Available Screens
            </Button>

            <Button
              size="small"
              variant="contained"
              style={{ marginLeft: '7px' }}
              onClick={() => {
                this.onDeleteButtonPressed();
              }}
              disabled={this.state.waitingForNetworkCall}
            >
              Delete Screen
            </Button>

            <Button
              size="small"
              variant="contained"
              style={{ marginLeft: '7px' }}
              onClick={() => {
                this.newBannerScreenPressed();
              }}
            >
              New Screen
            </Button>

            <Button
              size="small"
              variant="contained"
              style={{ marginLeft: '7px' }}
              onClick={() => {
                this.saveButtonPressed();
              }}
              disabled={this.state.waitingForNetworkCall}
            >
              Save Screen
            </Button>
          </div>


          {/* Screen Name */}
          <div
            style={{
              border: '1px solid #000',
              padding: '10px',
              width: '550px',
              marginTop: '15px',
              marginBottom: '15px',
              marginLeft: '20px',
              textAlign: 'left'
            }}>
            <label>Screen Name:</label>
            <input
              size="62"
              id="bannerScreenNameField"
              defaultValue={this.state.bannerScreenName}
              onChange={this.handleChangeBannerScreenName}
              style={{
                marginLeft: '15px',
                marginTop: '0px'
              }}
            />
          </div>


          <div
            style={{
              border: '1px solid #000',
              padding: '10px',
              width: '550px',
              marginLeft: '20px',
              marginBottom: '15px'
            }}>
            <div>

              <div
                style={{
                  marginTop: '10px',
                  border: '1px solid #000',
                  padding: '5px',
                  width: '538px',
                }}
              >
                <div
                  style={{
                    marginBottom: '15px',
                    marginLeft: '5px',
                    textAlign: 'left'
                  }}
                >
                  <label>Selected Carousels & Collections:</label>
                </div>

                {this.state.selectedCarousels.map((item) => (
                  <Grid container spacing={10}
                    style={{
                      marginBottom: '10px',
                    }} >
                    <Grid item xs={1}>

                      <Button
                        style={{
                          marginLeft: '5px',
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          this.moveCarouselUp(item);
                        }}>
                        Up
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        style={{
                          marginLeft: '5px',
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          this.moveCarouselDown(item);
                        }}>
                        Down
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <label>
                        {item.carouselName}
                      </label>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        style={{
                          marginLeft: '-25px',
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          this.removeButtonPressed(item);
                        }}>
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </div>

              <br></br>

              <div
                style={{
                  border: '1px solid #000',
                  padding: '5px',
                  textAlign: 'left',
                  width: '538px',
                }}
              >

                <div key={this.state.destinationPanelState} style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Button
                    id='collections'
                    onClick={this.toggleDestinationVisibility}
                    style={{
                      flex: '1',
                      marginBottom: '-15px',
                      paddingBottom: '10px',
                      backgroundColor: this.state.destinationPanelState === 'collections' ? INDICATOR_COLOR : 'transparent', 
                    }}
                  >
                    Collections
                  </Button>

                  <Button
                    id='carousels'
                    onClick={this.toggleDestinationVisibility}
                    style={{
                      flex: '1',
                      marginBottom: '-15px',
                      paddingBottom: '10px',
                      marginLeft: '20px',
                      backgroundColor: this.state.destinationPanelState === 'carousels' ? INDICATOR_COLOR : 'transparent',
                    }}
                  >
                    Carousels
                  </Button>

                </div>
                <br></br>

                {newDestinationsCard}

              </div>

            </div>

          </div>


          {/* Buttons */}
          <div
            style={{
              marginLeft: '20px',
              marginTop: '10px',
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              width: '550px'
            }}>

            <Button
              size="small"
              variant="contained"
              style={{ marginLeft: '7px' }}
              onClick={() => {
                this.showAvailableBannerScreens();
              }}
            >
              Available Screens
            </Button>

            <Button
              size="small"
              variant="contained"
              style={{ marginLeft: '7px' }}
              onClick={() => {
                this.onDeleteButtonPressed();
              }}
              disabled={this.state.waitingForNetworkCall}
            >
              Delete Screen
            </Button>

            <Button
              size="small"
              variant="contained"
              style={{ marginLeft: '7px' }}
              onClick={() => {
                this.newBannerScreenPressed();
              }}
            >
              New Screen
            </Button>

            <Button
              size="small"
              variant="contained"
              style={{ marginLeft: '7px' }}
              onClick={() => {
                this.saveButtonPressed();
              }}
              disabled={this.state.waitingForNetworkCall}
            >
              Save Screen
            </Button>
          </div>

        </div>
      );
    }
  }

  //
  showResourcePicker = (e) => {
    this.setState({ rpOpen: true });
  };

  // Remember Selected Collections
  handleCollectionSelection = (resources) => {
    const collections = resources.selection.map((collection) => collection);
    var selectedCarousels = [];
    var title, id, originalSrc;

    // convert Collections to Carousel with One Banner
    if (collections) {
      for (const collection of collections) {
        title = collection.title;
        id = collection.storefrontId;

        var image = collection.image;
        if (image) {
          originalSrc = image.originalSrc;
        } else {
          originalSrc = "";
        }

        // convert Collection to Destination
        var destination = {
          extraData: id,
          featureType: bbConstants.DESTINATION_COLLECTION,
          image: originalSrc,
          title: title,
        };

        // Carousel with no rotation and 1 destination
        var carousel = {
          autoRotateMilliSecondsPause: 0,
          bannerSize: bbConstants.BANNER_MEDIUM,
          carouselName: title,
          extraData: "",
          destinations: [destination],
        };

        selectedCarousels.push(carousel);
      }

      // remember the Banners in State
      var moreThanZero = selectedCarousels.length > 0;
      this.setState({ selectedCarousels, collectionsSet: moreThanZero });
    }

    // close the ResourcePicker
    this.setState({ rpOpen: false });
  };

  //
  saveButtonPressed() {
    var selectedCarousels = this.state.selectedCarousels;

    var carouselIds = [];
    if (selectedCarousels != null) {
      for (const carousel of selectedCarousels) {
        var carouselId = carousel.carouselId;
        if (carouselId === undefined) {
          carouselId = -1;
        }
        carouselIds.push(carouselId);
      }
    }

    var bannerScreenId = this.state.bannerScreenId;
    if (bannerScreenId == null) {
      bannerScreenId = 0;
    }
    // builld the BannerScreen Object for the API payload
    var payload = {
      shopID: this.state.shopID,
      bannerScreenName: this.state.bannerScreenName,
      carousels: selectedCarousels,
      carouselIds: carouselIds,
      bannerScreenId: bannerScreenId,
    };

    // ///////    Post to API   //////////////////////////////////////////////

    // Creating a XHR object
    let xhr = new XMLHttpRequest();

    //  addOrEditBannerScreenWithNewCarousels
    const url = BASE_URL + "/addOrEditBannerScreenWithCarouselIds";

    // open a connection
    xhr.open("POST", url, true);

    // Set the request header i.e. which type of content you are sending
    xhr.setRequestHeader("Content-Type", "application/json");

    var scope = this;

    // Create a state change callback
    xhr.onreadystatechange = function () {
      // message when we're done
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          alert("Success!  New Screen Created!");
          scope.props.reloadParent();
          scope.setState( { waitingForNetworkCall: false })
        } else {
          alert("Error!  Something went wrong when creating a new Screen");
        }
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);

    // Sending data with the request
    this.setState( { waitingForNetworkCall: true })
    xhr.send(data);
  }

  //  'Delete this Screen' button pressed
  onDeleteButtonPressed() {
    var bannerScreenId = this.state.bannerScreenId;
    this.postDeleteBannerScreenById(bannerScreenId);
  }

  // method to Delete the BannerScreen
  // also called from the BannerScreensResourceList
  deleteBannerScreen = (bannerScreen) => {
    var bannerScreenId = bannerScreen.bannerScreenId;
    this.postDeleteBannerScreenById(bannerScreenId);
  };

  //
  postDeleteBannerScreenById(bannerScreenId) {
    var payload = {
      shopID: this.state.shopID,
      bannerScreenId: bannerScreenId,
    };

    // ///////    Post to API   //////////////////////////////////////////////

    // Creating a XHR object
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/deleteBannerScreenByIdForShop";

    // open a connection
    xhr.open("POST", url, true);

    // Set the request header i.e. which type of content you are sending
    xhr.setRequestHeader("Content-Type", "application/json");

    var scope = this;

    // Create a state change callback
    xhr.onreadystatechange = function () {
      // message when we're done
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          alert("Screen successfully deleted!");

          // remove local banner screen
          var availableBannerScreens = scope.state.availableBannerScreens
          var bsCopies = [];
          for (const bs of availableBannerScreens) {
            if (bs.bannerScreenId !== bannerScreenId) {
              bsCopies.push(bs);
            }
          }
          scope.setState({ 
            availableBannerScreens: bsCopies,
            waitingForNetworkCall: false,
           })
          scope.props.reloadParent();
        } else {
          alert("Error!  Something went wrong when deleting the Screen");
        }
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);

    // Sending data with the request
    this.setState( { waitingForNetworkCall: true })
    xhr.send(data);
    // /////////////////////////////////////////////////////
  }

  //
  clearData() {
    // store.set(bbConstants.STORAGE_NAME_HOME_SELECTED_COLLECTIONS, null);
    // ??  Commented out for import to app-builder
    // store.set(bbConstants.STORAGE_NAME_DESTINATIONS, null);
    this.setState({ rpOpen: false });
  }

  //
  showAvailableBannerScreens = (e) => {
    // var displayMode = display_mode_available
    this.setState({ displayMode: display_mode_available });
  };


  //  Collections
  fetchCollections = async () => {

    // see if we already have the Collections
    var availableCollections = this.state.availableCollections
    if (availableCollections === null && availableCollections.length > 0) {
      return availableCollections;
    }

    try {
      const storefrontAccessToken = this.state.shopValue.api.apiKey;
      const shopifyStoreUrl = 'https://' + this.state.shopValue.api.shopDomain;

      const response = await axios.post(
        `${shopifyStoreUrl}/api/2021-10/graphql.json`,
        {
          query: `
          {
            collections(first: 100) {
              edges {
                node {
                  id
                  title
                  image {
                    originalSrc
                  }
                }
              }
            }
          }
        `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
          },
        }
      );

      availableCollections = response.data.data.collections.edges
      var trimmedAvailableCollections = [];
      for (const collection of availableCollections) {
        var trimmed = collection.node;
        trimmedAvailableCollections.push(trimmed)
      }

      this.setState({
        availableCollections: trimmedAvailableCollections,
        loadingCollections: false
      });
      return availableCollections;

    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

}

export default BannerScreenBuilder;
