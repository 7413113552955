import * as React from "react";

import { Card } from '@mui/material';

import * as bbConstants from "./constants/bb-constants";

// import {
//   BASE_URL,
//   plpSmallImages_imageUrl,
//   plpListGridview_imageUrl,
//   plpLargeImages_imageUrl,
// } from "./constants/endpointDefinition";

class PDPOptionsCard extends React.Component {
  state = {
    shopValue: this.props.shopValue,
    listType: this.props.shopValue.listType,

    pdpProductImagesStyle: this.props.shopValue.pdpProductImagesStyle,
    pdpVariantsStyle: this.props.shopValue.pdpVariantsStyle,
  };

  //
  handlePdpImagesStateChange = (e) => {
    var pdpProductImagesStyle = e.target.value;
    this.setState({ pdpProductImagesStyle });
    this.props.updatePdpProductImagesStyle(pdpProductImagesStyle);
  };

  //
  handlePdpVariantsStateChange = (e) => {
    var pdpVariantsStyle = e.target.value;
    this.setState({ pdpVariantsStyle });
    this.props.updatePdpVariantsStyle(pdpVariantsStyle);
  };

  //
  render() {
    var imagesStyle = this.state.pdpProductImagesStyle;
    var variantsStyle = this.state.pdpVariantsStyle;
    
    return (
      <Card
        variant="outlined"
        style={{
          margin: '0px',
          padding: '0px'
        }}>
        <label
          style={{
            margin: '15px',
            display: 'flex',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}>
          Product Details
        </label>

        <Card variant="outlined"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '15px',
            padding: '5px'
          }}>
          <label style={{ margin: '5px' }}>Main Images Appearance:</label>
          <div padding='5px'>
            <input
              type="radio"
              id="PDP_IMAGES_CAROUSEL"
              name="pdp_images_types"
              onChange={this.handlePdpImagesStateChange}
              value={bbConstants.PDP_IMAGES_CAROUSEL}
              checked={imagesStyle === bbConstants.PDP_IMAGES_CAROUSEL}
            />
            <label style={{ marginLeft: '10px' }}>Carousel of Images</label>
          </div>
          <div>
            <input
              type="radio"
              id="PDP_IMAGES_THUMBNAILS"
              name="pdp_images_types"
              onChange={this.handlePdpImagesStateChange}
              value={bbConstants.PDP_IMAGES_THUMBNAILS}
              checked={imagesStyle === bbConstants.PDP_IMAGES_THUMBNAILS}
            />
            <label style={{ marginLeft: '10px' }}>Thumbnails of Images</label>
          </div>

        </Card>

        <Card variant="outlined"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '15px',
            padding: '5px'
          }}
        >
          <label style={{ margin: '5px' }}>Product Variants Appearance:</label>
          <div padding='5px'>
            <input
              type="radio"
              id="PDP_VARIANTS_DROPDOWN"
              name="pdp_variants_types"
              onChange={this.handlePdpVariantsStateChange}
              value={bbConstants.PDP_VARIANTS_DROPDOWN}
              checked={variantsStyle === bbConstants.PDP_VARIANTS_DROPDOWN}
            />
            <label style={{ marginLeft: '10px' }}>Dropdown of Names</label>
          </div>
          <div>
            <input
              type="radio"
              id="PDP_IMAGES_THUMBNAILS"
              name="pdp_variants_types"
              onChange={this.handlePdpVariantsStateChange}
              value={bbConstants.PDP_VARIANTS_THUMBNAILS}
              checked={variantsStyle === bbConstants.PDP_VARIANTS_THUMBNAILS}
            />
            <label style={{ marginLeft: '10px' }}>Thumbnails of Images</label>
          </div>
        </Card>
      </Card>
    );
  }
}

export default PDPOptionsCard;
