export const BASE_URL =
  "https://qob7bezeh8.execute-api.us-east-1.amazonaws.com/dev";

export const plpSmallImages_imageUrl =
  "https://tools-dbapi.s3.us-east-2.amazonaws.com/plpSmallImages.jpeg";
export const plpListGridview_imageUrl =
  "https://tools-dbapi.s3.us-east-2.amazonaws.com/plpListGridview.jpeg";
export const plpLargeImages_imageUrl =
  "https://tools-dbapi.s3.us-east-2.amazonaws.com/plpLargeImages.jpeg";

// export const pdpCarousel_imageUrl =
//   "https://tools-dbapi.s3.us-east-2.amazonaws.com/plpSmallImages.jpeg";
// export const pdpThumbnails_imageUrl =
//   "https://tools-dbapi.s3.us-east-2.amazonaws.com/plpListGridview.jpeg";
