export { default as notFoundImage } from "./empty-state.svg";
export { default as trophyImage } from "./home-trophy.png";
export { default as nav_change_circle } from "./nav_change_circle.png";
export { default as nav_coffee_maker } from "./nav_coffee_maker.png";
export { default as nav_cognition } from "./nav_cognition.png";
export { default as nav_compost } from "./nav_compost.png";
export { default as nav_cookie } from "./nav_cookie.png";
export { default as nav_cruelty_free } from "./nav_cruelty_free.png";
export { default as nav_diamond } from "./nav_diamond.png";
export { default as nav_diversity_1 } from "./nav_diversity_1.png";
export { default as nav_diversity_2 } from "./nav_diversity_2.png";
export { default as nav_diversity_3 } from "./nav_diversity_3.png";
export { default as nav_eco } from "./nav_eco.png";
export { default as nav_emoji_nature } from "./nav_emoji_nature.png";
export { default as nav_emoji_objects } from "./nav_emoji_objects.png";
export { default as nav_favorite } from "./nav_favorite.png";
export { default as nav_female } from "./nav_female.png";
export { default as nav_forest } from "./nav_forest.png";
export { default as nav_groups } from "./nav_groups.png";
export { default as nav_handshake } from "./nav_handshake.png";
export { default as nav_health_and_safety } from "./nav_health_and_safety.png";
export { default as nav_home } from "./nav_home.png";
export { default as nav_local_cafe } from "./nav_local_cafe.png";
export { default as nav_male } from "./nav_male.png";
export { default as nav_military_tech } from "./nav_military_tech.png";
export { default as nav_mood } from "./nav_mood.png";
export { default as nav_neurology } from "./nav_neurology.png";
export { default as nav_person } from "./nav_person.png";
export { default as nav_pet_supplies } from "./nav_pet_supplies.png";
export { default as nav_pets } from "./nav_pets.png";
export { default as nav_potted_plant } from "./nav_potted_plant.png";
export { default as nav_psychiatry } from "./nav_psychiatry.png";
export { default as nav_psychology } from "./nav_psychology.png";
export { default as nav_psychology_alt } from "./nav_psychology_alt.png";
export { default as nav_public } from "./nav_public.png";
export { default as nav_recycling } from "./nav_recycling.png";
export { default as nav_restaurant } from "./nav_restaurant.png";
export { default as nav_rocket } from "./nav_rocket.png";
export { default as nav_rocket_launch } from "./nav_rocket_launch.png";
export { default as nav_sale } from "./nav_sale.png";
export { default as nav_sentiment_satisfied } from "./nav_sentiment_satisfied.png";
export { default as nav_sentiment_very_dissatisfied } from "./nav_sentiment_very_dissatisfied.png";
export { default as nav_settings } from "./nav_settings.png";
export { default as nav_shopping_bag } from "./nav_shopping_bag.png";
export { default as nav_skull } from "./nav_skull.png";
export { default as nav_star } from "./nav_star.png";
export { default as nav_storefront } from "./nav_storefront.png";
export { default as nav_sunny } from "./nav_sunny.png";
export { default as nav_thumb_down } from "./nav_thumb_down.png";
export { default as nav_thumb_up } from "./nav_thumb_up.png";
export { default as nav_token } from "./nav_token.png";
export { default as nav_transgender } from "./nav_transgender.png";
export { default as nav_view_cozy } from "./nav_view_cozy.png";
export { default as nav_water_drop } from "./nav_water_drop.png";
export { default as nav_workspace } from "./nav_workspace.png";


export { default as font_arial } from "./font_arial.png";
export { default as font_caveat_variable } from "./font_caveat_variable.png";
export { default as font_century_gothic } from "./font_century_gothic.png";
export { default as font_futura_light } from "./font_futura_light.png";
export { default as font_futura_medium } from "./font_futura_medium.png";
export { default as font_helvetica_light } from "./font_helvetica_light.png";
export { default as font_helvetica_neue } from "./font_helvetica_neue.png";
export { default as font_helvetica } from "./font_helvetica.png";
export { default as font_lugrasimo_regular } from "./font_lugrasimo_regular.png";
export { default as font_roboto_medium } from "./font_roboto_medium.png";
export { default as font_shadowsIntoLight_regular } from "./font_shadowsIntoLight_regular.png";
