import * as React from "react";
import axios from 'axios';

import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from '@mui/material';

import * as bbConstants from "./constants/bb-constants";
import { BASE_URL } from "./constants/endpointDefinition";
import { INDICATOR_COLOR } from "./constants/bb-constants";


class Notifications extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      shopID: this.props.shopID,
      apiKey: this.props.apiKey,
      shopDomain: this.props.shopDomain,
      title: "",
      body: "",
      subText: "",
      imageUrl: "",
      featureType: bbConstants.DESTINATION_COLLECTION,
      extraData: "",
      extraDataReadable: "<none>",
      destinations: [],
      destinationPanelState: 'none',

      availableProducts: [],
      availableCollections: [],
    };

    this.fetchProducts();
    this.fetchCollections();
  }


  //  Collections
  fetchCollections = async () => {

    // see if we already have the Collections
    var availableCollections = this.state.availableCollections
    if (availableCollections === null && availableCollections.length > 0) {
      return availableCollections;
    }

    try {
      const storefrontAccessToken = this.state.apiKey;
      const shopifyStoreUrl = 'https://' + this.state.shopDomain;

      const response = await axios.post(
        `${shopifyStoreUrl}/api/2021-10/graphql.json`,
        {
          query: `
          {
            collections(first: 100) {
              edges {
                node {
                  id
                  title
                  image {
                    originalSrc
                  }
                }
              }
            }
          }
        `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
          },
        }
      );

      availableCollections = response.data.data.collections.edges
      this.setState({
        availableCollections,
        loadingCollections: false
      });
      return availableCollections;

    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };


  //
  fetchProducts = async () => {
    // Check if products are already loaded
    const availableProducts = this.state.availableProducts;
    if (availableProducts && availableProducts.length > 0) {
      return availableProducts;
    }

    try {
      const storefrontAccessToken = this.state.apiKey;
      const shopifyStoreUrl = 'https://' + this.state.shopDomain;

      const response = await axios.post(
        `${shopifyStoreUrl}/api/2021-10/graphql.json`,
        {
          query: `
            {
              products(first: 100) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first: 1) {
                      edges {
                        node {
                          originalSrc
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
          },
        }
      );

      const products = response.data.data.products.edges;
      this.setState({
        availableProducts: products,
        loadingProducts: false,
      });
      return products;
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };


  // Destinations
  toggleDestinationVisibility = (e) => {
    var destinationPanelState = e.target.id
    if (this.state.destinationPanelState === destinationPanelState) {
      destinationPanelState = 'none'
    }
    this.setState({ destinationPanelState })
  };


  //
  addProductDestination = (e) => {
    const newProductId = e.target.value;
    const availableProducts = this.state.availableProducts;
    var newProduct = null;
    for (const product of availableProducts) {
      if (product.node.id === newProductId) {
        newProduct = product.node;
        break;
      }
    }

    var extraData = btoa(newProductId);
    var extraDataReadable = newProduct.title
    var featureType = bbConstants.DESTINATION_COLLECTION
    this.setState({ extraData, extraDataReadable, featureType })
  }

  //
  addCollectionDestination = (e) => {
    const newCollectionId = e.target.value;
    const availableCollections = this.state.availableCollections;
    var newCollection = null;
    for (const collection of availableCollections) {
      if (collection.node.id === newCollectionId) {
        newCollection = collection.node;
        break;
      }
    }

    var extraData = btoa(newCollectionId);
    var extraDataReadable = newCollection.title
    var featureType = bbConstants.DESTINATION_COLLECTION
    this.setState({ extraData, extraDataReadable, featureType })
  }


  //
  render() {
    var destination = "Feature Destination:  ";
    if (this.state.destinations.length > 0) {
      destination =
        "Destination set to ->  " +
        this.state.destinations[0].extraDataReadable;
    }


    const formControlStyle = {
      transition: `opacity 1s ease ${true ? '1.0s' : '1.0s'}`, // Adjust durations accordingly
      opacity: true ? 1 : 0, // Set initial opacity based on showFormControl state
      paddingLeft: '20px',
      paddingTop: '10px',
      marginLeft: '0',  // Set marginLeft to 0 to align the label to the left
      textAlign: 'left',  // Set textAlign to left for additional clarity
    };


    const containerStyle = {
      // border: '1px solid #000',
      padding: '10px',
      width: '530px',
      marginTop: '5px',
      marginBottom: '5px',
      textAlign: 'left',
    };


    const headerStyle = {
      padding: '10px',
      width: '530px',
      marginTop: '10px',
      marginBottom: '10px',
      textAlign: 'left',
      display: 'flex', // Use flex container
      justifyContent: 'space-between', // Space between items, pushing button to the right
    };

    // show the Destination:
    var destinationLabel = this.state.extraDataReadable;


    return (
      <Card
        style={{
          width: '550px',
          marginLeft: '15px',
          marginTop: '15px',
          border: '1px solid #000',
          padding: '10px',
        }}>


        <div style={headerStyle}>
          <label
            style={{
              paddingLeft: '0px',
              paddingTop: '10px',
              marginRight: 'auto',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}>
            Notification:
          </label>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              this.sendButtonPressed();
            }}
          >
            Send
          </Button>
        </div>


        <div style={containerStyle}>
          <label>Title:</label>
          <input
            size="67"
            onChange={this.handleTitleChange}
            style={{
              marginLeft: '15px',
              marginTop: '0px'
            }}
          />
        </div>

        <div style={containerStyle}>
          <div>
            <label>Sub Text (shows on most newer devices):</label>
          </div>
          <div style={{ marginTop: '10px' }}>
            <input
              size="73"
              onChange={this.handleSubTextChange}
              style={{
                marginLeft: '15px',
                marginTop: '0px'
              }}
            />
          </div>
        </div>

        <div style={containerStyle}>
          <div>
            <label>Notification Body:</label>
          </div>
          <div style={{ marginTop: '10px' }}>
            <input
              size="73"
              onChange={this.handleBodyChange}
              style={{
                marginLeft: '15px',
                marginTop: '0px'
              }}
            />
          </div>
        </div>



        <div
          style={{
            border: '1px solid #000',
            padding: '10px',
          }}>
          <div style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '15px'
          }}>
            <label style={{ paddingLeft: '20px', paddingTop: '10px', marginRight: 'auto' }}>
              Destination: &nbsp;&nbsp;&nbsp; {destinationLabel}
            </label>
          </div>

          <div key={this.state.destinationPanelState} 
            style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
            <Button
              id='collections'
              onClick={this.toggleDestinationVisibility}
              style={{
                flex: '1', marginBottom: '-10px', paddingBottom: '0px',
                backgroundColor: this.state.destinationPanelState === 'collections' ? INDICATOR_COLOR : 'transparent',
              }}
            >
              {this.state.destinationPanelState === 'collections' ? 'Hide Collections' : 'Collections'}
            </Button>

            <Button
              id='products'
              onClick={this.toggleDestinationVisibility}
              style={{
                flex: '1', marginBottom: '-15px', paddingBottom: '0px', marginLeft: '20px',
                backgroundColor: this.state.destinationPanelState === 'products' ? INDICATOR_COLOR : 'transparent',
              }}
            >
              {this.state.destinationPanelState === 'products' ? 'Hide Products' : 'Products'}
            </Button>
          </div>


          <div>
            <div style={formControlStyle}>
              {this.state.destinationPanelState === 'collections' && (
                <FormControl component="fieldset">
                  <RadioGroup
                    value={this.state.collectionId}
                    onChange={this.handleCollectionSelection}
                  >
                    {this.state.availableCollections.map((Collection) => (
                      <FormControlLabel
                        key={Collection.node.id}
                        value={Collection.node.id}
                        control={
                          <Button
                            size="small"
                            variant="contained"
                            onClick={this.addCollectionDestination}
                            style={{ margin: '8px', width: '480px' }}
                          >
                            {Collection.node.title}
                          </Button>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </div>


            <div style={formControlStyle}>
              {this.state.destinationPanelState === 'products' && (
                <FormControl component="fieldset">
                  <RadioGroup
                    value={this.state.productId}
                    onChange={this.handleProductSelection}
                  >
                    {this.state.availableProducts.map((Product) => (
                      <FormControlLabel
                        key={Product.node.id}
                        value={Product.node.id}
                        control={
                          <Button
                            size="small"
                            variant="contained"
                            onClick={this.addProductDestination}
                            style={{ margin: '8px', width: '480px' }}
                          >
                            {Product.node.title}
                          </Button>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </div>
          </div>
        </div>

      </Card>

    );
  }

  handleTitleChange = (e) => {
    var newTitle = e.target.value;
    this.setState({ title: newTitle });
  };
  handleBodyChange = (e) => {
    var newBody = e.target.value;
    this.setState({ body: newBody });
  };
  handleSubTextChange = (e) => {
    var newSubText = e.target.value;
    this.setState({ subText: newSubText });
  };

  //
  handleImageURLChange = (e) => {
    var imageUrl = e.target.value;
    this.setState({ imageUrl });
  };

  //
  saveDestinations = (e) => {
    this.setState({ destinations: e });
  };

  //
  sendButtonPressed = (e) => {
    var shopID = "" + this.state.shopID;
    var title = this.state.title;
    var body = this.state.body;
    var subText = this.state.subText;
    var imageUrl = this.state.imageUrl;
    var featureType = this.state.featureType;
    var extraData = this.state.extraData;
    var extraDataReadable = this.state.extraDataReadable;

    var notification = {
      shopID: shopID,
      title: title,
      body: body,
      subText: subText,
      imageUrl: imageUrl,
      extraData: extraData,
      extraDataReadable: extraDataReadable,
      featureType: featureType,
      // destinations: destinations,
    };

    var payload = notification;

    var availableCollections = this.state.availableCollections;
    var availableProducts = this.state.availableProducts;


    //  DEBUGGING!!!!!!!!   DEBUGGING!!!!!!!!    DEBUGGING!!!!!!!! 
    // return;

    // ///////    Post to API   //////////////////////////////////////////////
    // Creating a XHR object
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/testNotifications";

    var scope = this;

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      // message when we're done
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          alert("Success!  Notification Sent!  " + xhr.responseText);
          // clear state variables
          // scope.setState({
          //   title: "",
          //   body: "",
          //   imageUrl: "",
          //   featureType: "",
          //   extraData: "",
          //   extraDataReadable: "",
          //   destinations: [],
          // });
        } else {
          alert("Error!  Something went wrong sending the Notification");
        }
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);
    console.log("sending:" + JSON.stringify(data));

    // Sending data with the request
    xhr.send(data);

    // /////////////////////////////////////////////////////
  };
}

export default Notifications;
