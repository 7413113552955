import * as React from "react";

import {
  Button,
  Grid,
} from '@mui/material';


class DestinationRowItem extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      item: this.props.item,
      changeImageDivIdShowing: false,
      newImageURL: this.props.item.image
    };
  }

  //
  setNewImageURL = (e) => {
    const newImageURL = e.target.value;
    this.setState({ newImageURL })
  }

  //
  showChangeImageDiv = () => {
    const changeImageDivIdShowing = true;
    this.setState({ changeImageDivIdShowing })
  };
  //
  hideChangeImageDiv = () => {
    const changeImageDivIdShowing = false;
    this.setState({ changeImageDivIdShowing })
  };

  //
  changeImageForItem = () => {
    const item = this.state.item;
    item.image = this.state.newImageURL;
    this.hideChangeImageDiv();
    this.props.changeImageForItem(item);
  };

  //
  downButtonPressed = () => {
    const item = this.state.item;
    this.props.downButtonPressed(item);
  };

  //
  upButtonPressed = () => {
    const item = this.state.item;
    this.props.upButtonPressed(item);
  };

  //
  removeDestination = () => {
    const item = this.state.item;
    this.props.removeDestination(item);
  };


  //
  render() {

    var changeImageDiv = null;
    if (this.state.changeImageDivIdShowing) {
      changeImageDiv = (
        <div
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            width: '475px',
            marginTop: '10px'
          }}
        >
          <label
            style={{ marginRight: '10px', }}
          >
            New Image:
          </label>
          <input
            size="30"
            defaultValue={this.state.newImageURL}
            onChange={this.setNewImageURL}
            style={{
              marginLeft: '5px',
              marginTop: '2px'
            }}
          />

          <Button
            size="small"
            variant="contained"
            onClick={this.changeImageForItem}
            style={{ marginLeft: '15px' }}
          >
            Save
          </Button>

          <Button
            size="small"
            variant="contained"
            style={{ marginLeft: '15px' }}
            onClick={this.hideChangeImageDiv}
          >
            Cancel
          </Button>
        </div>
      )
    }

    return (
      <div
        style={{
          border: '1px solid #ccc',
          padding: '10px',
          margin: '5px'
        }}>

        <div>
          <Grid container spacing={2} style={{ marginBottom: '0px' }} >
            <Grid item xs={1}>
              <div>
                <img
                  alt={this.state.item.image ? this.state.item.image.altText : ""}
                  src={this.state.item.image}
                  style={{ width: '70px', height: '70px' }}
                />
              </div>
            </Grid>

            <Grid item xs={10}>
              <div>
                <div style={{ textAlign: 'left', marginBottom: '10px', marginTop: '5px' }}>
                  <label style={{ marginLeft: '40px' }}>
                    {this.state.item.title}
                  </label>
                </div>

                <div>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ marginLeft: '40px' }}
                    onClick={this.showChangeImageDiv}
                  >
                    Change Image
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    style={{ marginLeft: '15px' }}
                    onClick={this.upButtonPressed}
                  >
                    Up
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    style={{ marginLeft: '5px' }}
                    onClick={this.downButtonPressed}
                  >
                    Down
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    style={{ marginLeft: '15px' }}
                    onClick={this.removeDestination}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div>
          {changeImageDiv}
        </div>
      </div>

    );
  }

}

export default DestinationRowItem;
