import React from 'react';

import { Button } from '@mui/material';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password1: '',
      password2: '',
      waitingForNetworkCall: false,
    };
  }

  //
  handleSetUsername = (event) => {
    this.setState({ username: event.target.value });
  }

  //
  handleChangePassword1 = (event) => {
    this.setState({ password1: event.target.value });
  }

  //
  handleChangePassword2 = (event) => {
    this.setState({ password2: event.target.value });
  }


  //
  handleSubmit = (event) => {
    event.preventDefault();

    const username = this.state.username;
    const pw1 = this.state.password1;
    const pw2 = this.state.password2;

    // no user name
    if (username.length < 1) {
      alert("Please enter a username")
      return;
    }

    // passwords do not match
    if (pw1 !== pw2) {
      alert("Passwords do not match")
      return;
    }

    // password isn't long enough
    if (pw1.length < 8) {
      alert("Password must have at least 8 characters")
      return;
    }

    this.sendResetPasswordRequest();
  }


  //
  sendResetPasswordRequest = () => {
    var userName = this.state.username
    var password = this.state.password1

    const CryptoJS = require('crypto-js');
    const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

    var payload = {
      UserName: userName,
      Hashword: hashedPassword
    };


    var scope = this;
    let xhr = new XMLHttpRequest();
    const BASE_URL = "https://qob7bezeh8.execute-api.us-east-1.amazonaws.com/dev";
    let url = BASE_URL + "/resetUserPassword";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        scope.props.gotoLoginScreen();
      } else {
        // TODO - Error message to User
        console.log("sendResetPasswordRequest not 200  status: " + xhr.status);
      }
    };

    var data = JSON.stringify(payload);
    this.setState({ waitingForNetworkCall: true });  // starting network call
    xhr.send(data);
  };


  //
  render() {

    const labelStyle = {
      margin: '10px',
      width: '160px',
      textAlign: 'right',
    }
    const divStyle = {
      justifyContent: 'left',
      width: '500px',
      display: 'flex',
      alignItems: 'flex-center',
    }


    return (

      <div>

        <div style={divStyle}>
          <label style={labelStyle}>User name:</label>
          <input
            style={{ margin: '10px' }}
            size="40"
            type='text'
            value={this.state.username}
            onChange={this.handleSetUsername}
          />
        </div>

        <div style={divStyle}>
          <label style={labelStyle}>Enter Password:</label>
          <input
            style={{ margin: '10px' }}
            size="40"
            type='password'
            value={this.state.password1}
            onChange={this.handleChangePassword1}
          />
        </div>

        <div style={divStyle}>
          <label style={labelStyle}>Re-enter Password:</label>
          <input
            style={{ margin: '10px' }}
            size="40"
            type='password'
            value={this.state.password2}
            onChange={this.handleChangePassword2}
          />
        </div>

        <br></br>

        <Button
          size="small"
          variant="contained"
          style={{ margin: '15px' }}
          onClick={this.handleSubmit}
          disabled={this.state.waitingForNetworkCall}
        >
          Submit new password
        </Button>

      </div>
    );
  }
}

export default ResetPassword
