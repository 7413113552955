import * as React from "react";

// import { LockMajor } from "@shopify/polaris-icons";

import {
  Button,
  Card,
  CardActions,
  Link
} from '@mui/material';

import * as bbConstants from "./constants/bb-constants";

import { BASE_URL } from "./constants/endpointDefinition";
import AppStatusCard from "./AppStatusCard";
import ConsortiaCard from "./ConsortiaCard";
import CategoriesCard from "./CategoriesCard";


class ShopAdmin extends React.Component {
  state = {
    shopID: this.props.shopID,
    shopValue: this.props.shopValue,

    disableStoreNameCard: true,
    shopName: this.props.shopValue.name,

    disableApiKeyCard: true,
    apiKey: this.props.shopValue.api.apiKey,

    disableContactNameCard: true,
    contactName: this.props.shopValue.contactName,

    disableContactPhoneCard: true,
    contactPhone: this.props.shopValue.contactPhone,

    disableContactEmailCard: true,
    contactEmail: this.props.shopValue.contactEmail,

    categories: this.props.categories,
    shopDomain: this.props.shopValue.api.shopDomain,
    shopIcon: this.props.shopValue.shopLargeImageRowIcon,

    waitingForNetworkCall: false,
  };

  /*
    State variables are updated, but the original Shop is not being changed
    push changed Shop to parent
  */
  persistShop() {
    var shop = this.state.shopValue;
    this.props.setShop(shop);
  }

  //
  setInstalledState = (e) => {
    var shopValue = this.state.shopValue;
    var installedState = e;
    shopValue.installedState = installedState;
    this.setState({ shopValue, waitingForNetworkCall: true });
    this.sendInstalledState(installedState);
  };

  //
  setShopName = (e) => {
    var shopName = e.target.value;
    var shopValue = this.state.shopValue;
    shopValue.name = shopName;

    this.setState({ shopName, shopValue });
    this.persistShop(shopValue);
  };

  //
  setShopApi = (e) => {
    var apiKey = e.target.value;
    var shopValue = this.state.shopValue;
    shopValue.api.apiKey = apiKey;

    this.setState({ apiKey, shopValue });
    this.persistShop(shopValue);
  };

  //
  setShopDomain = (e) => {
    var shopDomain = e.target.value;
    var shopValue = this.state.shopValue;
    shopValue.api.shopDomain = shopDomain;

    this.setState({ shopDomain, shopValue });
    this.persistShop(shopValue);
  };

  //
  setContactName = (e) => {
    var contactName = e.target.value;
    var shopValue = this.state.shopValue;
    shopValue.contactName = contactName;

    this.setState({ contactName, shopValue });
    this.persistShop(shopValue);
  };
  //
  setContactPhone = (e) => {
    var contactPhone = e.target.value;
    var shopValue = this.state.shopValue;
    shopValue.contactPhone = contactPhone;

    this.setState({ contactPhone, shopValue });
    this.persistShop(shopValue);
  };
  //
  setContactEmail = (e) => {
    var contactEmail = e.target.value;
    var shopValue = this.state.shopValue;
    shopValue.contactEmail = contactEmail;

    this.setState({ contactEmail, shopValue });
    this.persistShop(shopValue);
  };
  //
  setShopIcon = (e) => {
    var shopIcon = e.target.value;
    var shopValue = this.state.shopValue;
    shopValue.shopLargeImageRowIcon = shopIcon;

    this.setState({ shopIcon, shopValue });
    this.persistShop(shopValue);
  };

  //
  consortiaSelected = (consortiaState) => {
    var consortia = [];

    var smallBusinessChecked = consortiaState.smallBusinessChecked;
    var blackOwnedChecked = consortiaState.blackOwnedChecked;
    var womenOwnedChecked = consortiaState.womenOwnedChecked;
    var lgbtqOwnedChecked = consortiaState.lgbtqOwnedChecked;
    var sustainableOwnedChecked = consortiaState.sustainableOwnedChecked;

    if (smallBusinessChecked) {
      consortia.push(bbConstants.CAT_SMALL_BUSINESS);
    }
    if (blackOwnedChecked) {
      consortia.push(bbConstants.CAT_BLACK);
    }
    if (womenOwnedChecked) {
      consortia.push(bbConstants.CAT_WOMEN);
    }
    if (lgbtqOwnedChecked) {
      consortia.push(bbConstants.CAT_LGBTQ);
    }
    if (sustainableOwnedChecked) {
      consortia.push(bbConstants.CAT_SUSTAINABLE);
    }

    var shopValue = this.state.shopValue;
    shopValue["consortia"] = consortia;
    this.setState({ shopValue });
  };

  //
  shopCategoriesSelected = (shopCategories) => {
    var shopValue = this.state.shopValue;
    shopValue["categories"] = shopCategories;
    this.setState({ shopValue });
  };

  // Lock buttons
  setStoreNameButtonPressed = () => {
    var disableStoreNameCard = false;
    this.setState({ disableStoreNameCard });
  };
  setAPIKeyButtonPressed = () => {
    var disableApiKeyCard = false;
    this.setState({ disableApiKeyCard });
  };
  setContactNameButtonPressed = () => {
    var disableContactNameCard = false;
    this.setState({ disableContactNameCard });
  };
  setContactPhoneButtonPressed = () => {
    var disableContactPhoneCard = false;
    this.setState({ disableContactPhoneCard });
  };
  setContactEmailButtonPressed = () => {
    var disableContactEmailCard = false;
    this.setState({ disableContactEmailCard });
  };

  //
  render() {

    const labelStyle = {
      margin: '10px',
      width: '120px',
      textAlign: 'right',
    }
    const divStyle = {
      justifyContent: 'left',
      width: '500px',
      display: 'flex',
      alignItems: 'flex-center',
    }
    const inputStyle = {
      margin: '10px',
      width: '200px'
    }


    // Store Name Card
    var disableStoreNameCard = this.state.disableStoreNameCard;
    var StoreNameCard = null;
    if (disableStoreNameCard) {
      StoreNameCard = (
        <div style={divStyle}>
          <label style={labelStyle}>Store Name:</label>
          <input
            style={inputStyle}
            size="30"
            value={this.state.shopName}
            onChange={this.setShopName}
            disabled={true}
          />
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              this.setStoreNameButtonPressed();
            }}
            size="slim"
          >unlock</Button>
        </div>
      );
    } else {
      StoreNameCard = (
        <div style={divStyle}>
          <label style={labelStyle}>Store Name:</label>
          <input
            style={{ margin: '10px' }}
            size="30"
            value={this.state.shopName}
            onChange={this.setShopName}
          />
        </div>
      );
    }

    // Contact Name Card
    var disableContactNameCard = this.state.disableContactNameCard;
    var ContactNameCard = null;
    if (disableContactNameCard) {
      ContactNameCard = (
        <div style={divStyle}>
          <label style={labelStyle}>Contact Name:</label>
          <input
            style={inputStyle}
            size="30"
            value={this.state.contactName}
            onChange={this.setContactName}
            disabled={true}
          />
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              this.setContactNameButtonPressed();
            }}
            size="slim"
          >unlock</Button>
        </div>
      );
    } else {
      ContactNameCard = (
        <div style={divStyle}>
          <label style={labelStyle}>Contact Name:</label>
          <input
            style={inputStyle}
            size="30"
            value={this.state.contactName}
            onChange={this.setContactName}
          />
        </div>
      );
    }

    // Contact Phone Card
    var disableContactPhoneCard = this.state.disableContactPhoneCard;
    var ContactPhoneCard = null;
    if (disableContactPhoneCard) {
      ContactPhoneCard = (
        <div style={divStyle}>
          <label style={labelStyle}>Contact Phone:</label>
          <input
            style={inputStyle}
            size="30"
            value={this.state.contactPhone}
            onChange={this.setContactPhone}
            disabled={true}
          />
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              this.setContactPhoneButtonPressed();
            }}
            size="slim"
          >unlock</Button>
        </div>
      );
    } else {
      ContactPhoneCard = (
        <div style={divStyle}>
          <label style={labelStyle}>Contact Phone:</label>
          <input
            style={inputStyle}
            size="30"
            value={this.state.contactPhone}
            onChange={this.setContactPhone}
          />
        </div>
      );
    }

    // Contact Email Card
    var disableContactEmailCard = this.state.disableContactEmailCard;
    var ContactEmailCard = null;
    if (disableContactEmailCard) {
      ContactEmailCard = (
        <div style={divStyle}>
          <label style={labelStyle}>Contact Email:</label>
          <input
            style={inputStyle}
            size="30"
            value={this.state.contactEmail}
            onChange={this.setContactEmail}
            disabled={true}
          />
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              this.setContactEmailButtonPressed();
            }}
            size="slim"
          >unlock</Button>
        </div>
      );
    } else {
      ContactEmailCard = (
        <div style={divStyle}>
          <label style={labelStyle}>Contact Email:</label>
          <input
            style={inputStyle}
            size="30"
            value={this.state.contactEmail}
            onChange={this.setContactEmail}
          />
        </div>
      );
    }

    // API Key Card
    var disableApiKeyCard = this.state.disableApiKeyCard;
    var ApiKeyCard = null;
    if (disableApiKeyCard) {
      ApiKeyCard = (
        <div style={divStyle}>
          <label style={labelStyle}>API key:</label>
          <input
            style={inputStyle}
            value={this.state.apiKey}
            onChange={this.setShopApi}
            disabled={true}
            size="35"
          />
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              this.setAPIKeyButtonPressed();
            }}
            size="slim"
          >unlock</Button>
        </div>
      );
    } else {
      ApiKeyCard = (
        <div style={divStyle}>
          <label style={labelStyle}>API key:</label>
          <input
            style={inputStyle}
            value={this.state.apiKey}
            onChange={this.setShopApi}
            size="35"
          />
        </div>
      );
    }

    // consortia variables
    var consortia = this.state.shopValue.consortia;
    var smallBusinessChecked = true;
    var blackOwnedChecked = true;
    var womenOwnedChecked = true;
    var lgbtqOwnedChecked = true;
    var sustainableOwnedChecked = true;

    if (consortia != null) {
      smallBusinessChecked = consortia.includes(bbConstants.CAT_SMALL_BUSINESS);
      blackOwnedChecked = consortia.includes(bbConstants.CAT_BLACK);
      womenOwnedChecked = consortia.includes(bbConstants.CAT_WOMEN);
      lgbtqOwnedChecked = consortia.includes(bbConstants.CAT_LGBTQ);
      sustainableOwnedChecked = consortia.includes(bbConstants.CAT_SUSTAINABLE);
    }

    // installedState and previewCode
    let installedState = this.state.shopValue.installedState;
    let previewCode = this.state.shopValue.previewCode;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'left'
        }}>
        <Card variant="outlined" style={{ padding: '30px' }}>

          <AppStatusCard key={this.state.waitingForNetworkCall}
            installedState={installedState}
            previewCode={previewCode}
            setInstalledState={this.setInstalledState}
            waitingForNetworkCall={this.state.waitingForNetworkCall}
          />

          <br></br>

          <Card variant="outlined" title="Store Info">
            <label
              style={{
                paddingLeft: '20px',
                paddingTop: '10px',
                display: 'flex',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}>
              Store Info
            </label>

            {StoreNameCard}
            {ContactNameCard}
            {ContactPhoneCard}
            {ContactEmailCard}
            {ApiKeyCard}

            <Link
              external="true"
              target="_blank"
              rel="noopener noreferrer"
              href="https://appassets.s3.us-east-2.amazonaws.com/Generate+Shopify+API+Key/jan+2024/GenerateMobileAppAPIKeyJan2024.html"
              underline="always"
            >
              How to create a ‘Shopify API key’
            </Link>
            <div style={{ marginBottom: '10px' }}></div>
          </Card>

          <br></br>

          <CategoriesCard
            shopValue={this.state.shopValue}
            categories={this.state.categories}
            shopCategoriesSelected={this.shopCategoriesSelected}
          />



          <br></br>

          <ConsortiaCard
            shopValue={this.state.shopValue}
            consortiaSelected={this.consortiaSelected}
            smallBusinessChecked={smallBusinessChecked}
            blackOwnedChecked={blackOwnedChecked}
            womenOwnedChecked={womenOwnedChecked}
            lgbtqOwnedChecked={lgbtqOwnedChecked}
            sustainableOwnedChecked={sustainableOwnedChecked}
          />

          <br></br>

          <Card variant="outlined">
            <label
              style={{
                paddingLeft: '20px',
                paddingTop: '10px',
                display: 'flex',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}>
              Directory Icon:
            </label>
            <br></br>
            <label>Image URL:</label>
            <input
              style={{ margin: '10px' }}
              size="50"
              value={this.state.shopIcon}
              onChange={this.setShopIcon}
            />
            <br></br>
            <div>
              Use a .jpg or .png with an aspect ratio of 1x3.
            </div>
            <div>
              Recommended dimensions are 600px by 200px.
            </div>
            <br></br>
            <div>
              <img
                width={300}
                height={100}
                alt="Logo of your Shop"
                src={this.state.shopIcon} />
            </div>
            <br></br>
          </Card>

          <CardActions style={{ marginLeft: '50px' }}>
            <Button
              size="small"
              variant="contained"
              style={{ margin: '15px' }}
              onClick={this.sendUpdatedShop}
              disabled={this.state.waitingForNetworkCall}
            >
              Submit Changes
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }

  //
  sendUpdatedShop = (e) => {
    if (null != e) {
      e.preventDefault();
    }

    let shop = this.state.shopValue;

    var payload = {
      mallID: 2,
      shop,
    };

    var scope = this;
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/add-addOrUpdateShop";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var shopValue = JSON.parse(this.responseText);
        scope.setState({ shopValue });
        scope.props.setShop(shopValue);

        // re-lock the locked fields
        var disableApiKeyCard = true;
        var disableStoreNameCard = true;
        scope.setState({ 
          disableApiKeyCard, 
          disableStoreNameCard,
          waitingForNetworkCall: false
        });

        alert("Success!  Changes submitted!");

        /*  
        // The modifed Shop is being returned by the API in the response Body text  
        // The new Shop needs to be parsed and propogated
        */
      } else {
        // TODO - Error message to User
        console.log("AES sendUpdatedShop not 200  status: " + xhr.status);
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);

    // Sending data with the request
    this.setState( { waitingForNetworkCall: true })
    xhr.send(data);
  };

  //
  sendInstalledState(installedState) {
    let shopID = this.state.shopValue.shopId;

    var payload = {
      shopID,
      installedState,
    };

    var scope = this;
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/setInstalledState";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        alert("Success!  Changes submitted!");
        scope.setState( {waitingForNetworkCall: false });
      } else {
        // TODO - Error message to User
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);

    // Sending data with the request
    xhr.send(data);
  }
}

export default ShopAdmin;
