import * as React from "react";
import axios from 'axios';

import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup
} from '@mui/material';

import * as bbConstants from "./constants/bb-constants";
import { BASE_URL } from "./constants/endpointDefinition";
import DestinationsResourceList from "./DestinationsResourceList";
import { INDICATOR_COLOR } from "./constants/bb-constants";


class AddEditCarousel extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      shopID: this.props.shopID,
      shopValue: this.props.shopValue,
      selectedCarousel: this.props.selectedCarousel,
      selectedAutoRotateOption: this.getAutoRotateLabel(
        this.props.selectedAutoRotateOption
      ),
      carouselName: this.props.carouselName,
      destinations: this.props.destinations,
      carouselSize: this.props.carouselSize,
      availableCarousels: this.props.availableCarousels,

      // Destinations
      apiKey: this.props.shopValue.api.apiKey,
      shopDomain: this.props.shopValue.api.shopDomain,
      showFormControl: false,
      availableProducts: [],
      availableCollections: [],
      destinationPanelState: 'none',
      newImageURL: "",

      waitingForNetworkCall: false,
    };

    this.fetchProducts();
    this.fetchCollections();
  };


  // Destinations
  toggleDestinationVisibility = (e) => {
    var destinationPanelState = e.target.id
    if (this.state.destinationPanelState === destinationPanelState) {
      destinationPanelState = 'none'
    }
    this.setState({ destinationPanelState })
  };

  // Show image destination card
  toggleImageVisibility = () => {
    this.setState({ destinationPanelState: 'image' })
  };


  //
  handleSizeStateChange = (e) => {
    var carouselSize = e;
    this.setState({ carouselSize });
  };


  //
  addCollectionDestination = (e) => {
    const newCollectionId = e.target.value;
    const availableCollections = this.state.availableCollections;
    var newCollection = null;
    for (const collection of availableCollections) {
      if (collection.node.id === newCollectionId) {
        newCollection = collection.node;
        break;
      }
    }
    var base64id = btoa(newCollectionId);

    var image = "";
    try {
      image = newCollection.image.originalSrc;
    } catch (error) {
      console.error('Error accessing originalSrc:', error);
    }

    var newDestination = {
      extraData: base64id,
      featureType: bbConstants.DESTINATION_COLLECTION,
      image: image,
      title: newCollection.title
    }

    var selectedCarousel = this.state.selectedCarousel
    selectedCarousel.destinations.push(newDestination)
    this.setState({ selectedCarousel })
    // Does this need to be pushed up?
  }


  //
  addProductDestination = (e) => {
    const newProductId = e.target.value;
    const availableProducts = this.state.availableProducts;
    var newProduct = null;
    for (const product of availableProducts) {
      if (product.node.id === newProductId) {
        newProduct = product.node;
        break;
      }
    }
    var base64id = btoa(newProductId);

    var newDestination = {
      extraData: base64id,
      featureType: bbConstants.DESTINATION_PRODUCT,
      image: newProduct.images.edges[0].node.originalSrc,
      title: newProduct.title
    }

    var selectedCarousel = this.state.selectedCarousel
    selectedCarousel.destinations.push(newDestination)
    this.setState({ selectedCarousel })
    // Does this need to be pushed up?
  }


  //
  addImageDestination = (e) => {
    var newDestination = {
      extraData: "Image",
      featureType: bbConstants.DESTINATION_NONE,
      image: this.state.newImageURL,
      title: "Image"
    }

    var selectedCarousel = this.state.selectedCarousel
    selectedCarousel.destinations.push(newDestination)
    this.setState({
      selectedCarousel,
      destinationPanelState: 'none'
    });
  }


  // 
  hideChangeImageDiv = (e) => {
    this.setState({ destinationPanelState: 'none' })
  }


  //
  setNewImageURL = (e) => {
    const newImageURL = e.target.value;
    this.setState({ newImageURL })
  }

  //
  render() {
    // 'auto roatate' options
    var delayValueLabels = [];
    var delayValues = bbConstants.AUTO_ROTATE_DELAY_CONSTANTS;
    for (const dv of delayValues) {
      delayValueLabels.push(dv.label);
    }

    var sizeDescriptions = bbConstants.BANNER_SIZE_DESCRIPTIONS_CONSTANTS;
    var bannerSizeDecsription = "default";
    var bannerImageUrl = "default";
    for (const sd of sizeDescriptions) {
      if (this.state.carouselSize === sd.label) {
        bannerSizeDecsription = sd.value;
        bannerImageUrl = sd.url;
      }
    }

    const carouselSizes = [
      { value: bbConstants.BANNER_PENCIL, label: bbConstants.BANNER_PENCIL_READABLE },
      { value: bbConstants.BANNER_SMALL, label: bbConstants.BANNER_SMALL_READABLE },
      { value: bbConstants.BANNER_MEDIUM, label: bbConstants.BANNER_MEDIUM_READABLE },
      { value: bbConstants.BANNER_TALL, label: bbConstants.BANNER_TALL_READABLE },
    ];


    // Destinations
    const formControlStyle = {
      transition: `opacity 1s ease ${true ? '1.0s' : '1.0s'}`, // Adjust durations accordingly
      opacity: true ? 1 : 0, // Set initial opacity based on showFormControl state
      paddingLeft: '20px',
      paddingTop: '10px',
      marginLeft: '0',  // Set marginLeft to 0 to align the label to the left
      textAlign: 'left',  // Set textAlign to left for additional clarity
    };

    return (
      <Card
        style={{
          width: '550px',
          marginLeft: '15px',
          marginTop: '15px',
          border: '1px solid #000',
          padding: '10px',
        }}>


        {/* Back and Save Buttons */}
        <div
          style={{
            border: '1px solid #000',
            padding: '10px',
            width: '530px',
            marginTop: '0px',
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            size="small"
            variant="contained"
            style={{ flex: '1', marginLeft: '0px' }}
            onClick={this.showAvailableCarousels}
          >
            Back to Available Carousels
          </Button>

          <Button
            size="small"
            variant="contained"
            style={{ flex: '1', marginLeft: '15px' }}
            onClick={this.saveButtonPressed}
            disabled={this.state.waitingForNetworkCall}
          >
            Save This Carousel
          </Button>
        </div>

        <div
          style={{
            border: '1px solid #000',
            padding: '10px',
            width: '530px',
            marginTop: '15px',
            marginBottom: '15px',
            marginLeft: '0px',
            textAlign: 'left'
          }}>
          <label>Carousel Name:</label>
          <input
            size="57"
            defaultValue={this.state.carouselName}
            onChange={this.handleChangeBannerCarouselName}
            style={{
              marginLeft: '15px',
              marginTop: '0px'
            }}
          />
        </div>


        <div
          style={{
            border: '1px solid #000',
            padding: '10px',
            width: '530px',
          }}
        >
          {/* Carousel Size and Preview Image */}
          <Grid container spacing={2} style={{ marginBottom: '10px' }} >
            {/* Left side (radio buttons) */}
            <Grid item xs={4}>
              <label style={{
                display: 'flex',
                paddingLeft: '10px',
                paddingTop: '10px',
                marginRight: 'auto',
                marginLeft: '5px',
                marginBottom: '5px'
              }}>
                Carousel Size:
              </label>

              <div>
                {carouselSizes.map((size) => (
                  <div key={size.value} style={{ display: 'flex', alignItems: 'center', marginLeft: '13px' }}>
                    <input
                      type="radio"
                      id={size.value}
                      name="carousel_sizes"
                      onChange={() => this.handleSizeStateChange(size.value)}
                      value={size.value}
                      checked={this.state.carouselSize === size.value}
                    />
                    <label style={{ marginLeft: '5px' }}>{size.label}</label>
                    <br />
                  </div>
                ))}
              </div>

            </Grid>

            {/* Right side (image) */}
            <Grid item xs={8}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '0px',
              }}>
                <div>{bannerSizeDecsription}</div>
                <img style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '20px'
                }}
                  width={200} src={bannerImageUrl} alt="Banner" />
              </div>
            </Grid>
          </Grid>
        </div>

        <br></br>

        <div
          style={{
            border: '1px solid #000',
            padding: '10px',
            width: '530px',
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '15px'
          }}>
          <label
            style={{
              paddingLeft: '20px',
              paddingTop: '0px'
            }}>
            Auto Rotate Delay:
          </label>

          <select
            onChange={(e) => this.handleInputChange("selectedAutoRotateOption")(e.target.value)}
            value={this.state.selectedAutoRotateOption}
            style={{ marginLeft: '20px', marginTop: '2px' }}
          >
            {delayValueLabels.map((option) => (
              <option key={option} value={option.value}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div
          style={{
            border: '1px solid #000',
            padding: '10px',
            marginLeft: '0',
            textAlign: 'left',
          }}>
          <label
            style={{
              paddingLeft: '20px',
              paddingTop: '0px',
              marginLeft: '0',
              textAlign: 'left',
            }}>
            Current Destinations
          </label>

          <DestinationsResourceList
            key={this.state.destinations}
            id="destinationsResourceList"
            destinations={this.state.destinations}
            saveDestinations={this.saveDestinations}
            moveDestinationUp={this.moveDestinationUp}
            moveDestinationDown={this.moveDestinationDown}
            removeDestination={this.removeDestination}
            changeImageForItem={this.changeImageForItem}
          />
        </div>

        <br></br>
        <div
          style={{
            border: '1px solid #000',
            padding: '10px',
          }}>
          <div style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '15px'
          }}>
            <label style={{ paddingLeft: '20px', paddingTop: '10px', marginRight: 'auto' }}>
              Add Destinations
            </label>
          </div>

          <div key={this.state.destinationPanelState} style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              id='collections'
              onClick={this.toggleDestinationVisibility}
              style={{
                flex: '1', marginBottom: '-15px', paddingBottom: '10px',
                backgroundColor: this.state.destinationPanelState === 'collections' ? INDICATOR_COLOR : 'transparent',
              }}
            >
              {this.state.destinationPanelState === 'collections' ? 'Hide Collections' : 'Collections'}
            </Button>

            <Button
              id='products'
              onClick={this.toggleDestinationVisibility}
              style={{
                flex: '1', marginBottom: '-15px', paddingBottom: '10px', marginLeft: '20px',
                backgroundColor: this.state.destinationPanelState === 'products' ? INDICATOR_COLOR : 'transparent',
              }}
            >
              {this.state.destinationPanelState === 'products' ? 'Hide Products' : 'Products'}
            </Button>

            <Button
              id='image'
              onClick={this.toggleImageVisibility}
              style={{
                flex: '1', marginBottom: '-15px', paddingBottom: '10px', marginLeft: '20px',
                backgroundColor: this.state.destinationPanelState === 'image' ? INDICATOR_COLOR : 'transparent',
              }}
            >
              Image Only
            </Button>
          </div>

          <div>
            <div style={formControlStyle}>
              {this.state.destinationPanelState === 'collections' && (
                <FormControl component="fieldset">
                  <RadioGroup
                    value={this.state.collectionId}
                    onChange={this.handleCollectionSelection}
                  >
                    {this.state.availableCollections.map((Collection) => (
                      <FormControlLabel
                        key={Collection.node.id}
                        value={Collection.node.id}
                        control={
                          <Button
                            size="small"
                            variant="contained"
                            onClick={this.addCollectionDestination}
                            style={{ margin: '8px', width: '480px' }}
                          >
                            {Collection.node.title}
                          </Button>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </div>


            <div style={formControlStyle}>
              {this.state.destinationPanelState === 'products' && (
                <FormControl component="fieldset">
                  <RadioGroup
                    value={this.state.productId}
                    onChange={this.handleProductSelection}
                  >
                    {this.state.availableProducts.map((Product) => (
                      <FormControlLabel
                        key={Product.node.id}
                        value={Product.node.id}
                        control={
                          <Button
                            size="small"
                            variant="contained"
                            onClick={this.addProductDestination}
                            style={{ margin: '8px', width: '480px' }}
                          >
                            {Product.node.title}
                          </Button>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </div>


            <div style={formControlStyle}>
              {this.state.destinationPanelState === 'image' && (
                <div
                  style={{
                    border: '1px solid #ccc',
                    padding: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '475px',
                    marginTop: '10px'
                  }}
                >
                  <label
                    style={{ marginRight: '10px', }}
                  >
                    Image URL:
                  </label>
                  <input
                    size="30"
                    defaultValue={this.state.newImageURL}
                    onChange={this.setNewImageURL}
                    style={{
                      marginLeft: '5px',
                      marginTop: '2px'
                    }}
                  />

                  <Button
                    size="small"
                    variant="contained"
                    onClick={this.addImageDestination}
                    style={{ marginLeft: '15px' }}
                  >
                    Save
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    style={{ marginLeft: '15px' }}
                    onClick={this.hideChangeImageDiv}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>

          </div>

        </div>
      </Card>

    );
  }

  //
  handleInputChange(field, second) {
    return (value) => this.setState({ [field]: value });
  }


  //
  handleChangeBannerCarouselName = (e) => {
    var carouselName = e.target.value;
    var selectedCarousel = this.state.selectedCarousel;
    selectedCarousel.carouselName = carouselName;
    this.setState({
      carouselName,
      selectedCarousel,
    })
  }


  //
  saveDestinations = (e) => {
    this.setState({ destinations: e });
  };


  //
  moveDestinationUp = (e) => {
    var destinations = this.state.destinations;
    var item = e;
    var index = destinations.indexOf(item);

    if (0 === index) {
      // ignore it - can't move any higher
    } else {
      var previous = destinations[index - 1];
      destinations[index - 1] = item;
      destinations[index] = previous;
      this.saveDestinations(destinations);
    }
  };


  //
  moveDestinationDown = (e) => {
    var destinations = this.state.destinations;
    var item = e;
    var index = destinations.indexOf(item);
    var length = destinations.length;

    if (length === index + 1) {
      // ignore it - can't move any lower
    } else {
      var next = destinations[index + 1];
      destinations[index + 1] = item;
      destinations[index] = next;
      this.saveDestinations(destinations);
    }
  };


  //
  removeDestination = (e) => {
    var destinations = this.state.destinations;
    // var item = e.item;
    var item = e;
    var index = destinations.indexOf(item);

    destinations.splice(index, 1);
    this.saveDestinations(destinations);
  };


  //
  changeImageForItem = (item) => {
    var destinations = this.state.destinations;
    for (const destination of destinations) {
      if (destination.destinationId === item.destinationId) {
        destination.image = item.image;
      }
    }
    this.saveDestinations(destinations);
  };


  //
  showAvailableCarousels = (e) => {
    this.props.showAvailableCarousels();
  };


  //
  saveButtonPressed = (e) => {
    var carouselName = this.state.carouselName;

    // get the BannerSizeConstant 'value' for this 'label'
    var bannerSizeLabel = this.state.carouselSize;

    // get the autorotate 'value' (number) for this 'label'
    var autoRotateMilliSecondsPause = this.getAutoRotateMilliSecondsPause(
      this.state.selectedAutoRotateOption
    );

    // new banner structure
    var carouselId = -1;
    try {
      var selectedCarousel = this.state.selectedCarousel;
      carouselId = selectedCarousel.carouselId;
    } catch (e) {
      // TODO - Error message to User
      console.log("saveButtonPressed carouselId doesn't exist  e: ", e);
    }

    var newCarousel = {
      carouselId: carouselId,
      carouselName: carouselName,
      bannerSize: bannerSizeLabel,
      autoRotateMilliSecondsPause: autoRotateMilliSecondsPause * 1000,
      extraData: "",
      destinations: this.state.destinations,
    };


    var payload = {
      shopID: this.state.shopID,
      carousel: newCarousel,
    };

    // ///////    Post to API   //////////////////////////////////////////////
    // Creating a XHR object
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/add-carouselExternal";

    // see if we're updating an existing Carousel
    if (carouselId > 0) {
      url = BASE_URL + "/add-updateCarouselById";
    }

    var scope = this;
    var availableCarousels = this.state.availableCarousels;

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      // message when we're done
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          alert("Success!  New Carousel Created!  " + xhr.responseText);
          var newCarouselId = xhr.responseText;
          newCarousel.carouselId = newCarouselId;

          // Propagate to Parent - should really be in the successful response
          var newAvailableCarousels = [];

          var matchFound = false;
          for (const carousel of availableCarousels) {
            if (carousel.carouselId === newCarousel.carouselId) {
              newAvailableCarousels.push(newCarousel);
              matchFound = true;
            } else {
              // TODO - Error message to User
              console.log("Something went wrong creating a new carousel");
              newAvailableCarousels.push(carousel);
            }
          }

          if (!matchFound) {
            newAvailableCarousels.push(newCarousel);
          }

          scope.setState({ 
            availableCarousels: newAvailableCarousels,
            waitingForNetworkCall: false,
          });
          scope.props.updateAvailableCarousels(newAvailableCarousels); // tell parent
        } else {
          alert("Error!  Something went wrong when creating a new Carousel");
        }
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);

    // Sending data with the request
    this.setState( { waitingForNetworkCall: true })
    xhr.send(data);
  };

  
  // get the autorotate 'value' (number) for this 'label'
  getAutoRotateMilliSecondsPause(autoRotateSpeedLabel) {
    var autoRotateMilliSecondsPause = 0;
    var delayValues = bbConstants.AUTO_ROTATE_DELAY_CONSTANTS;
    for (const dv of delayValues) {
      if (dv.label === autoRotateSpeedLabel) {
        autoRotateMilliSecondsPause = dv.autoRotateMilliSecondsPause;
        break;
      }
    }
    return autoRotateMilliSecondsPause;
  }

  // get the autorotate 'label' for this 'value'
  getAutoRotateLabel(autoRotateSpeedValue) {
    var autoRotateLabel = 0;
    var delayValues = bbConstants.AUTO_ROTATE_DELAY_CONSTANTS;

    for (const dv of delayValues) {
      if (dv.autoRotateMilliSecondsPause === autoRotateSpeedValue / 1000) {
        autoRotateLabel = dv.label;
        break;
      }
    }
    return autoRotateLabel;
  }


  //  Collections
  fetchCollections = async () => {

    // see if we already have the Collections
    var availableCollections = this.state.availableCollections
    if (availableCollections === null && availableCollections.length > 0) {
      return availableCollections;
    }

    try {
      const storefrontAccessToken = this.state.apiKey;
      const shopifyStoreUrl = 'https://' + this.state.shopDomain;

      const response = await axios.post(
        `${shopifyStoreUrl}/api/2021-10/graphql.json`,
        {
          query: `
          {
            collections(first: 100) {
              edges {
                node {
                  id
                  title
                  image {
                    originalSrc
                  }
                }
              }
            }
          }
        `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
          },
        }
      );

      availableCollections = response.data.data.collections.edges
      this.setState({
        availableCollections,
        loadingCollections: false
      });
      return availableCollections;

    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };


  //
  fetchProducts = async () => {
    // Check if products are already loaded
    const availableProducts = this.state.availableProducts;
    if (availableProducts && availableProducts.length > 0) {
      return availableProducts;
    }

    try {
      const storefrontAccessToken = this.state.apiKey;
      const shopifyStoreUrl = 'https://' + this.state.shopDomain;

      const response = await axios.post(
        `${shopifyStoreUrl}/api/2021-10/graphql.json`,
        {
          query: `
            {
              products(first: 100) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first: 1) {
                      edges {
                        node {
                          originalSrc
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
          },
        }
      );

      const products = response.data.data.products.edges;
      this.setState({
        availableProducts: products,
        loadingProducts: false,
      });
      return products;
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

}

export default AddEditCarousel;
