import * as React from "react";

import {
  Box,
  Button,
  Card,
  Tabs,
  Tab,
} from '@mui/material';


import * as bbConstants from "./constants/bb-constants";
import NavigationTab from "./NavigationTab";

import { BASE_URL } from "./constants/endpointDefinition";


class NavigationParentCard extends React.Component {
  state = {
    saveButtonDisabled: false, // always enabled?
    navigationTabs: this.props.navigation,
    selectedTabIndex: 0,
    shopValue: this.props.shopValue,
  };

  //  send navigation to Parent
  updateNavigation = (navigation) => {
    var tabId = this.state.selectedTabIndex;
    var navigationTabId = navigation.tabId;

    if (null == navigationTabId) {
      navigation.tabId = tabId + 1; // tab Ids start at 1
    }

    var navigationTabs = this.state.navigationTabs;
    navigationTabs[tabId] = navigation;

    this.setState({ navigationTabs });
    this.props.updateNavigation(navigationTabs); // calls AESv2.updateNavigation
  };


  //
  moveTabLeft = (navigation) => {
    var tabId = this.state.selectedTabIndex;
    if (tabId === 0) {
      return;
    }

    // make sure the tab has a tabId
    var navigationTabId = navigation.tabId;
    if (null == navigationTabId) {
      navigation.tabId = tabId + 1; // tab Ids start at 1
    }

    var navigationTabs = this.state.navigationTabs;

    var init = tabId;
    var target = tabId - 1;

    // adjust tabIds - +1 because tab Ids start at 1
    navigationTabs[init].tabId = target + 1;
    navigationTabs[target].tabId = init + 1;

    this.arraymove(navigationTabs, init, target);

    this.handleTabChange(target);

    // propogate changes
    const selectedTabIndex = target;
    this.setState({ navigationTabs, selectedTabIndex });
    this.props.updateNavigation(navigationTabs); // calls AESv2.updateNavigation
  };


  //
  moveTabRight = (navigation) => {
    var tabId = this.state.selectedTabIndex;
    var navigationTabs = this.state.navigationTabs;
    if (tabId === navigationTabs.length - 1) {
      return;
    }

    // make sure the tab has a tabId
    var navigationTabId = navigation.tabId;
    if (null == navigationTabId) {
      navigation.tabId = tabId + 1; // tab Ids start at 1
    }

    var init = tabId;
    var target = tabId + 1;

    // adjust tabIds - +1 because tab Ids start at 1
    navigationTabs[init].tabId = target + 1;
    navigationTabs[target].tabId = init + 1;

    this.arraymove(navigationTabs, init, target);

    this.handleTabChange(target); //  can we switch seleted tab?

    // propogate changes
    const selectedTabIndex = target;
    this.setState({ navigationTabs, selectedTabIndex });
    this.props.updateNavigation(navigationTabs); // calls AESv2.updateNavigation
  };


  // https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }


  //
  onDeleteButtonPressed = () => {
    var navigationTabs = this.state.navigationTabs;
    var selectedTabIndex = this.state.selectedTabIndex;
    navigationTabs.splice(selectedTabIndex, 1);
    
    selectedTabIndex = 0;
    this.setState({ navigationTabs, selectedTabIndex });

    this.props.updateNavigation(navigationTabs); // calls AESv2.updateNavigation
  };


  //
  handleTabChange = (something, selectedTabIndex) => {
    this.setState({ selectedTabIndex });
  };


  //
  sendUpdatedShop = (e) => {
    if (null != e) {
      e.preventDefault();
    }

    let shop = this.state.shopValue;
    
    var payload = {
      mallID: 2,
      shop,
    };

    var scope = this;
    let xhr = new XMLHttpRequest();
    let url = BASE_URL + "/add-addOrUpdateShop";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var shopValue = JSON.parse(this.responseText);
        scope.setState({ 
          shopValue,
          waitingForNetworkCall: false 
        });
        scope.props.setShop(shopValue);
        alert("Success!  Changes submitted!");

      } else {
        // TODO - Error message to User
        console.log("NPC sendUpdatedShop not 200  status: " + xhr.status);
      }
    };

    // Converting JSON data to string
    var data = JSON.stringify(payload);
    // console.log("NPC sending: ", data);
    
    // Sending data with the request
    this.setState( { waitingForNetworkCall: true })
    xhr.send(data);
  };


  //
  render() {
    var tabPanels = [];
    var tabs = [];
    var navigationTabs = this.state.navigationTabs;
    var index = 1;

    var disabledDeleteButton = this.state.navigationTabs.length < 2;

    for (const navigationTab of navigationTabs) {
      // tabPanels
      var featureType = navigationTab.featureType;
      tabPanels.push(
        <NavigationTab
          navigationTab={navigationTab}
          updateNavigation={this.updateNavigation}
          featureType={featureType}
          shopValue={this.props.shopValue}
          onDeleteButtonPressed={this.onDeleteButtonPressed}
          disabledDeleteButton={disabledDeleteButton}
          moveTabLeft={this.moveTabLeft}
          moveTabRight={this.moveTabRight}
        />
      );

      // tabs
      var tabId = "tab" + index;
      var tabContent = "Tab " + index;
      tabs.push({
        id: tabId,
        title: tabId,
        content: tabContent,
      });

      index = index + 1;
    }
    
    //  AddTab Tab
    if (index < 5) {
      tabPanels.push(
        <NavigationTab
          updateNavigation={this.updateNavigation}
          featureType={bbConstants.DESTINATION_ALL_COLLECTION_BANNERS_SCREEN}
          shopValue={this.props.shopValue}
          onDeleteButtonPressed={this.onDeleteButtonPressed}
          disabledDeleteButton={disabledDeleteButton}
          moveTabLeft={this.moveTabLeft}
          moveTabRight={this.moveTabRight}
        />
      );

      var tabIdIndex = "tab" + index;
      tabs.push({
        id: tabIdIndex,
        title: "+ Add Tab +",
        content: "+ Add Tab +",
      });
    }

    const selectedTabPanel = tabPanels[this.state.selectedTabIndex]
    
    return (
      <Card key={this.state.selectedTabIndex}>
        <Tabs
          value={this.state.selectedTabIndex}
          onChange={this.handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.title} />
          ))}
        </Tabs>
        <Box key={this.state.selectedTabIndex}>{selectedTabPanel}</Box>

        <Button
          size="small"
          variant="contained"
          style={{ margin: '15px' }}
          onClick={this.sendUpdatedShop}
          disabled={this.state.waitingForNetworkCall}
        >
          Save Changes
        </Button>
      </Card>
    );
  }

}

export default NavigationParentCard;
