import * as React from "react";

import { Card, Checkbox, FormControlLabel } from '@mui/material';
import * as bbConstants from "./constants/bb-constants";

class ConsortiaCard extends React.Component {
  state = {
    shopValue: this.props.shopValue,
    smallBusinessChecked: this.props.smallBusinessChecked,
    blackOwnedChecked: this.props.blackOwnedChecked,
    womenOwnedChecked: this.props.womenOwnedChecked,
    lgbtqOwnedChecked: this.props.lgbtqOwnedChecked,
    sustainableOwnedChecked: this.props.sustainableOwnedChecked,
  };

  //
  handleSelectChange = (checkboxLabel, checked) => {
    var checkboxName = checkboxLabel.target.id;
    var smallBusinessChecked = this.state.smallBusinessChecked;
    var blackOwnedChecked = this.state.blackOwnedChecked;
    var womenOwnedChecked = this.state.womenOwnedChecked;
    var lgbtqOwnedChecked = this.state.lgbtqOwnedChecked;
    var sustainableOwnedChecked = this.state.sustainableOwnedChecked;

    if (checkboxName === bbConstants.CAT_SMALL_BUSINESS) {
      smallBusinessChecked = checked;
    }
    if (checkboxName === bbConstants.CAT_BLACK) {
      blackOwnedChecked = checked;
    }
    if (checkboxName === bbConstants.CAT_WOMEN) {
      womenOwnedChecked = checked;
    }
    if (checkboxName === bbConstants.CAT_LGBTQ) {
      lgbtqOwnedChecked = checked;
    }
    if (checkboxName === bbConstants.CAT_SUSTAINABLE) {
      sustainableOwnedChecked = checked;
    }

    var consortiaState = {
      smallBusinessChecked,
      blackOwnedChecked,
      womenOwnedChecked,
      lgbtqOwnedChecked,
      sustainableOwnedChecked,
    };

    this.setState(consortiaState);

    this.props.consortiaSelected(consortiaState);
  };

  //
  render() {
    return (
      <Card>
        <label
          style={{
            paddingLeft: '20px',
            paddingTop: '10px',
            display: 'flex',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}>
          Consortia:
        </label>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '15px' }}>
          <FormControlLabel
            control={
              <Checkbox
                id={bbConstants.CAT_SMALL_BUSINESS}
                checked={this.state.smallBusinessChecked}
                onChange={this.handleSelectChange}
                style={{ margin: '-5px 0' }}
              />
            }
            label="Small Business"
          />
          <FormControlLabel
            control={
              <Checkbox
                id={bbConstants.CAT_BLACK}
                checked={this.state.blackOwnedChecked}
                onChange={this.handleSelectChange}
                style={{ margin: '-5px 0' }}
              />
            }
            label="Black Owned"
          />
          <FormControlLabel
            control={
              <Checkbox
                id={bbConstants.CAT_WOMEN}
                checked={this.state.womenOwnedChecked}
                onChange={this.handleSelectChange}
                style={{ margin: '-5px 0' }}
              />
            }
            label="Woman Owned"
          />
          <FormControlLabel
            control={
              <Checkbox
                id={bbConstants.CAT_LGBTQ}
                checked={this.state.lgbtqOwnedChecked}
                onChange={this.handleSelectChange}
                style={{ margin: '-5px 0' }}
              />
            }
            label="LGBTQ+ Owned"
          />
          <FormControlLabel
            control={
              <Checkbox
                id={bbConstants.CAT_SUSTAINABLE}
                checked={this.state.sustainableOwnedChecked}
                onChange={this.handleSelectChange}
                style={{ margin: '-5px 0' }}
              />
            }
            label="Sustainably Focused"
          />
        </div>
      </Card>
    );
  }
}

export default ConsortiaCard;
